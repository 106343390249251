import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import '../src/styles/Footer.css';
import { useAuth } from "./AuthContext";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaDiscord } from "react-icons/fa";
import LoginIcon from '@mui/icons-material/Login';

const Footer = () => {
  const { auth } = useAuth();
  return (
    <Box
      sx={{
        backgroundColor: "#0B99FF",
        color: "white",
        py: 4,
        mt: 4,
        textAlign: "center",
        fontFamily: "Roboto", // Set Roboto font globally for the footer
      }}
    >
      <Grid container justifyContent="center" spacing={2}>
        {/* Footer Sections */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
          We provide simple and clear insights for Nifty futures and options trading. Our platform shows real-time data for option strikes, helping both buyers and sellers understand market trends. Whether you’re new or experienced, our tools are designed to help you make smarter decisions and stay ahead in your trading journey.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
                }}
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Home
              </Link>
            </li>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/live-nifty-index"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
                }}
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Live Charts
              </Link>
            </li>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/nifty-history"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
                }}
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Nifty History
              </Link>
            </li>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/terms-and-conditions"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
                }}
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Terms & Conditions
              </Link>
            </li>
            <li style={{ marginTop: "10px" }}> {/* Added spacing */}
                <Link
                  to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
                  }}
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1.6, marginBottom: 2 }}>
            Email: support@915trade.com
            <br />
            Address: Evolve Work Studio, Yelenahalli, 4th Floor, The hub, @Raj Serenity, Bangalore - 560068
          </Typography>
          <Link
            to="https://www.instagram.com/915trade.com_official/"
            style={{
              color: "white",
              textDecoration: "none",
              marginRight: "8px",
            }}
            target="_blank"
          >
            <InstagramIcon style={{ color: "white" }} />
          </Link>
          <Link
            to="https://www.youtube.com/@915Trade.com_official"
            style={{
              color: "white",
              textDecoration: "none",
              marginRight: "8px",
            }}
            target="_blank"
          >
            <YouTubeIcon style={{ color: "white" }} />
          </Link>
          <Link
            to="https://discord.gg/Xxxmz3Cv"
            style={{
              color: "white",
              textDecoration: "none",
            }}
            target="_blank"
          >
            <FaDiscord style={{ marginRight: "8px", color: "white", fontSize: "24px" }} />
          </Link>
          {/* Add the X Login link conditionally */}
          {auth.role === "admin" && (
            <Link
              to="/kite-login"
              style={{
                color: "white",
                textDecoration: "none",
                marginLeft: "8px",
              }}
            >
              <LoginIcon style={{ color: "white", fontSize: "24px" }} />
            </Link>
          )}
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box sx={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)", mt: 4, pt: 2 }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} 915Trade. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
import React, { useState, useEffect, useRef } from "react";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import StrikeBars from "../charts/StrikeBars";
import BrokerBuyDiffChart from "../charts/BrokerBuyDiffChart";
import { useAuth } from "../../AuthContext";
import { Grid, Box, IconButton, Select, FormControl, MenuItem } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const BrokerNiftyLive = () => {
  const { auth, axiosInstance } = useAuth();
  const [lineData, setLineData] = useState(null);
  const [secondLineData, setSecondLineData] = useState(null);
  const [strikesData, setStrikesData] = useState(null);
  const [maximizedCell, setMaximizedCell] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const [strikeComparisonData, setStrikeComparisonData] = useState(null);
  const [strikeComparison10MinsData, setStrikeComparison10MinsData] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState("Nifty");
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const isMarketOpen = () => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const marketOpenMinutes = 9 * 60 + 15; // 9:15 AM
    const marketCloseMinutes = 15 * 60 + 30; // 3:30 PM
    return (
      currentMinutes >= marketOpenMinutes && currentMinutes < marketCloseMinutes
    );
  };

  const clearTimers = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const computeSecondLineData = (data) => {
    if (!data || !data.CE || !data.PE) return null;

    return {
      ...data,
      CE: data.PE.map((peItem, index) => ({
        candleTime: peItem.candleTime,
        sell_volume: peItem.sell_volume - data.CE[index]?.sell_volume || 0,
      })),
      PE: [],
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lineResponse = await axiosInstance.post(`/get-nifty-index-lines`, {index: "NIFTY"});
        const lineChartData = lineResponse.data;

        const candleResponse = await axiosInstance.post(`/get-nifty-index-candles`, {index: "NIFTY"});
        const candleChartData = candleResponse.data;

        const strikesResponse = await axiosInstance.post(`/get-nifty-nearest-strike-buysell`, {index: "NIFTY"});
        const strikesData = strikesResponse.data;

        const strikeComparisonResponse = await axiosInstance.post(`/get-nifty-nearest-strike-buypressure`, {index: "NIFTY"});
        const strikeComparisonData = strikeComparisonResponse.data;

        const strikeComparison10MinsResponse = await axiosInstance.post(`/get-nifty-nearest-strike-buypressure-more`, {index: "NIFTY"});
        const strikeComparison10MinsData = strikeComparison10MinsResponse.data;

        setStrikesData(strikesData);
        setLineData(lineChartData);
        setCandleData(candleChartData);
        setStrikeComparisonData(strikeComparisonData);
        setStrikeComparison10MinsData(strikeComparison10MinsData)

        const computedData = computeSecondLineData(lineChartData);
        setSecondLineData(computedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Failed to fetch data. Please check the API.");
      }
    };

    const fetchInitialDataAndStartTimer = async () => {
      await fetchData();

      if (isMarketOpen()) {
        const refreshData = async () => {
          if (isMarketOpen()) {
            await fetchData();
          } else {
            clearTimers();
          }
        };

        const now = new Date();
        const delay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

        timeoutRef.current = setTimeout(() => {
          refreshData();
          intervalRef.current = setInterval(refreshData, 60000);
        }, delay);
      } else {
        console.warn("Market is closed. Timer will not start.");
      }
    };

    fetchInitialDataAndStartTimer();

    return clearTimers;
  }, [auth.token, axiosInstance]);

  const handleMaximize = (cellId) => {
    setMaximizedCell(cellId);
  };

  const handleMinimize = () => {
    setMaximizedCell(null);
  };

  const renderCell = (cellId, content) => {
    const isMaximized = maximizedCell === cellId;

    return (
      <Box
        sx={{
          position: "relative",
          height: isMaximized ? "100vh" : "100%",
          width: "100%",
          backgroundColor: "#f5f5f5",
          border: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: isMaximized ? 1300 : "auto",
        }}
      >
        <IconButton
          onClick={() =>
            isMaximized ? handleMinimize() : handleMaximize(cellId)
          }
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1400,
          }}
        >
          {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        {content}
      </Box>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        overflowY: maximizedCell ? "auto" : "hidden",
        flexDirection: "row",
      }}
    >
      {maximizedCell && (
        <Grid item xs={12}>
          {renderCell(
            maximizedCell,
            maximizedCell === 1
              ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
              : maximizedCell === 2
              ? secondLineData && (
                  <LineChart
                    data={secondLineData}
                    legends={["Diff Volume"]}
                  />
                )
              : maximizedCell === 3
              ? candleData && <CandleChart data={candleData} />
              : strikesData && <StrikeBars data={strikesData} isHistorical={false} />
          )}
        </Grid>
      )}
      {[1, 2, 3, 4]
        .filter((id) => id !== maximizedCell)
        .map((id) => (
          <Grid
            item
            xs={6}
            key={id}
            sx={{
              height: "50vh",
            }}
          >
            {renderCell(
              id,
              id === 1
                ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                : id === 2
                ? secondLineData && (
                    <LineChart
                      data={secondLineData}
                      legends={["Diff Volume"]}
                    />
                  )
                : id === 3
                ? candleData && <CandleChart data={candleData} />
                : strikesData && <StrikeBars data={strikesData} isHistorical={false} />
            )}
          </Grid>
        ))}
      <Grid item xs={12} sx={{ height: "70vh" }}>
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            backgroundColor: "#f5f5f5",
            border: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {strikeComparisonData && (
            <BrokerBuyDiffChart data={strikeComparisonData} isHistorical={false} last10MinData={strikeComparison10MinsData} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default BrokerNiftyLive;
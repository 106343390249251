import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const IndexHistory = () => {
  const [currentSection, setCurrentSection] = useState(0);

  const sections = [
    {
      title: 'Index History - Buy & Sell Volume Chart',
      content: (
        <>
        <Typography variant="body1" gutterBottom>
            Since we are in history section, the user will have to first select the Index from the first dropdown and then select the date for which they would like to see the data. This is shown in the below images.
        </Typography>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            }}
        >
            <img
            src="/index_history1.png"
            alt="Index History select trade date"
            style={{
                width: '100%',
                maxWidth: '900px',
                height: '300px',
                borderRadius: '8px',
            }}
            />
        </Box>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            }}
        >
            <img
            src="/index_history11.png"
            alt="Index History select trade date"
            style={{
                width: '100%',
                maxWidth: '900px',
                height: '300px',
                borderRadius: '8px',
            }}
            />
        </Box>
        <Typography variant="body1" gutterBottom>
            Once trade date is selected, the system bring the data and displays all charts. The first chart is Buy & Sell Volumes.
        </Typography>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            }}
        >
            <img
            src="/index_history2.png"
            alt="Index Buy vs Sell Volumes"
            style={{
                width: '100%',
                maxWidth: '800px',
                height: '500px',
                borderRadius: '8px',
            }}
            />
        </Box>
        <Typography variant="body1" gutterBottom>
            1. The data represents Buy vs Sell contracts at Futures level. It helps to understand the Buy vs Sell pressure at futures level.
        </Typography>
        <Typography variant="body1" gutterBottom>
            2. The red line represents Sell contracts/volumes, and the green line represents Buy contracts.
        </Typography>
        <Typography variant="body1" gutterBottom>
            3. The idea is to be able to understand whether Buyers are storng or Sellers are strong.
        </Typography>
        <Typography variant="body1" gutterBottom>
            4. We have observed, for Index price to continue to slide, the sellers will have to continue to sell more contracts.
        </Typography>
        <Typography variant="body1" gutterBottom>
            5. Similarly, for price to rise, the buyers will have to continue to buy more contracts.
        </Typography>
        <Typography variant="body1" gutterBottom>
            6. If the line is constant, it usually means not enough new contracts are being either Bought or Sold.
        </Typography>
        <Typography variant="body1" gutterBottom>
            7. You might see days when clearly either buyer or seller is dominant and never leaves their positions as seen below.
        </Typography>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            }}
        >
            <img
            src="/index_history3.png"
            alt="Index Buy vs Sell Volumes"
            style={{
                width: '100%',
                maxWidth: '800px',
                height: '500px',
                borderRadius: '8px',
            }}
            />
        </Box>
        <Typography variant="body1" gutterBottom>
            8. You might also see one side being dominant for a while and then the other side gaining momentum eventually as seen below.
        </Typography>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            }}
        >
            <img
            src="/live_nifty_chart12.png"
            alt="Index Buy vs Sell Volumes"
            style={{
                width: '100%',
                maxWidth: '800px',
                height: '500px',
                borderRadius: '8px',
            }}
            />
        </Box>
        <Typography variant="body1" gutterBottom>
            9. As you could see from the above image, sellers were dominant in the morning and then buyers gained momentum eventually.
        </Typography>
        </>
      ),
    },
    {
      title: 'Index History - Buy (minus) Sell Volume Chart',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the second chart displayed on the Index History Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/index_history4.png"
                alt="Index Buy vs Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	The data represents the difference between Buy and Sell contracts at the Futures level (Buy - Sell).
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	It reflects the minute-by-minute difference between the Green and Red lines shown in the first chart.
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.	The purpose of this chart is to identify subtle patterns in Buyer and Seller behavior, particularly during periods of low liquidity.
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.	The line below 0 indicates stronger selling activity, while the line above 0 indicates stronger buying activity.
            </Typography>
            <Typography variant="body1" gutterBottom>
                5.	A descending line signifies sustained selling pressure, whereas an ascending line indicates sustained buying pressure.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.	The image below illustrates a day when buying pressure outweighed selling pressure.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/nifty_live_chart22.png"
                alt="Index Buy vs Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
        </>
      ),
    },
    {
      title: 'Index History - Spot Candles Chart',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the third chart displayed on the Index History Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/index_history5.png"
                alt="Index Buy vs Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	These are Index Spot 1 minute candles.
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	Since ATM, OTM and ITM is decided based on Index Spot price, we show these candles, to make it easy to corelate with other data.
            </Typography>
        </>
      ),
    },
    {
      title: 'Index History - Strike-wise Buy & Sell Volume Chart',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the fourth chart displayed on the Nifty Live Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/index_history6.png"
                alt="Strike wise Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	You can see strike wise Buy vs Sell strength. Buy is in Green and Sell is in Red.
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	Buy bars are nothing but Put Sell volumes. Since higher put sell volumes push nifty UP we show it in green.
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.	Sell bars are nothing but Call Sell volumes. Since higher call sell volumes push nifty DOWN we show it in green.
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.	At any given point in time we show ATM Strike, 5 strikes below and 5 strikes above.
            </Typography>
            <Typography variant="body1" gutterBottom>
                5.	You can select the time of the day from the dropdown in top left corner to see data of that time. Depending on where Spot price was during that time, the ATM, +5 and -5 strikes will be selected and displayed accordingly.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.	The top right corner of this chart we show consolidated numbers of Buy vs Sell and the total difference.
            </Typography>
            <Typography variant="body1" gutterBottom>
                7.	For example, in the above image, you see Buy at 14.4M, Sell at 13.9M and Diff as 491K.
            </Typography>
            <Typography variant="body1" gutterBottom>
                8.	If Put sellers are high, the diff will be positive, if Call sellers are high, the diff will be negative.
            </Typography>
            <Typography variant="body1" gutterBottom>
                9.	The volumes on bars give you an idea on the liquidity as well. We request the user to view as much historical data as possible to get a sense of volumes and how they reveal High liquidity and Low liquidity days.
            </Typography>
            <Typography variant="body1" gutterBottom>
                10.	In general, strikes below the ATM Put sellers (Buyers) will be strong, and strikes above the ATM, Call sellers (Sellers) will be strong.
            </Typography>
            <Typography variant="body1" gutterBottom>
                11.	You can click on each strike Bars to reveal how the volumes played out till that moment on both Put and Call side. Refer the below image for example.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/index_history7.png"
                alt="Strike wise Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                12.	We see end of the day view of 23700 strike in the above image. This feature brings clarity on whether Put or Call have strength at each strike.
            </Typography>
            <Typography variant="body1" gutterBottom>
                13.	User can scroll down in this modal window to see Call and Put 1 minute candles till that time, as seen in below images.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/index_history8.png"
                alt="Strike wise Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/index_history9.png"
                alt="Strike wise Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
        </>
      ),
    },
    {
      title: 'Index History - Strike wise Buyers Insights',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the fifth chart displayed on the Index History Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/index_history10.png"
                alt="Index Buy vs Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	These are Call and Put Buy volumes at each strike.
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	The ATM strike is decided based on Nifty Spot price.
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.	At any given point in time we show 5 strikes below and 5 strikes above ATM.
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.	It is observed that on expiry day these volumes are significantly higher than on other days.
            </Typography>
            <Typography variant="body1" gutterBottom>
                5.	You can use the dropdown in top left corner to see the data of a selected time during that day. Changing the time refreshes both 4th and 5th charts.
            </Typography>
        </>
      ),
    },
  ];

  const handleNext = () => {
    setCurrentSection((prev) => {
      const nextSection = (prev + 1) % sections.length;
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page
      return nextSection;
    });
  };
  
  const handlePrevious = () => {
    setCurrentSection((prev) => {
      const previousSection = (prev - 1 + sections.length) % sections.length;
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page
      return previousSection;
    });
  };

  return (
    <Box sx={{ marginTop: '40px', padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        {sections[currentSection].title}
      </Typography>
      <Box
        sx={{
          width: '150px',
          height: '4px',
          backgroundColor: '#1976d2',
          marginBottom: '20px',
        }}
      />

      {/* Content */}
      {sections[currentSection].content}

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button
          variant="outlined"
          onClick={handlePrevious}
          disabled={currentSection === 0}
        >
          Previous Section
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={currentSection === sections.length - 1}
        >
          Next Section
        </Button>
      </Box>
    </Box>
  );
};

export default IndexHistory;
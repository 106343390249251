import React from "react";
import { Box, Typography } from "@mui/material";
import Header from "./Header";

const TermsAndConditions = () => {
  return (
    <Box>
      <Box
        sx={{
          padding: { xs: 3, md: 6 },
          maxWidth: "900px",
          margin: "0 auto",
          lineHeight: "1.8",
        }}
      >
        <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold" }}>
          Terms and Conditions
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          Welcome to our platform. By accessing or using our services, you agree
          to comply with and be bound by the following terms and conditions
          ("Terms"). Please read these Terms carefully before using our
          platform.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          1. General Terms
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - Our platform provides data insights, charts, and analytics based on
          market data. We do not provide any advisory or
          investment services.
          <br />
          - The subscription purchased by users grants access to our software
          and associated features. It does not include personalized financial
          advice or guarantees of any kind.
          <br />
          - All content, data, and analytics provided are for informational
          purposes only.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          2. No Advisory Services
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - We are not SEBI (Securities and Exchange Board of India) or NSE
          (National Stock Exchange) registered advisors. We do not offer any
          advisory services.
          <br />
          - We only provide software-based insights, charts, and analytics in a
          Software-as-a-Service (SAAS) model. Users are solely responsible for
          interpreting and acting on the data provided.
          <br />
          - Users should seek professional financial advice before making any
          investment decisions.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          3. No Guarantees or Promises
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - We do not guarantee any returns on investments or promote any
          specific trading strategies.
          <br />
          - Users acknowledge that trading and investing involve inherent risks,
          and we are not responsible for any financial losses incurred.
          <br />
          - Our platform does not commit to the accuracy, reliability, or completeness
          of the data or insights provided.
          <br />
          - From time to time, the platform may experience technical issues, bugs, or
          outages. Users acknowledge that such issues may occur and accept full
          responsibility for any decisions made or losses incurred during such periods.
          <br />
          - We do not accept any liability for damages or losses resulting from errors,
          delays, interruptions, or other issues on the platform.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          4. Limitation of Liability
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - We are not liable for any damages or losses arising from the use of
          our platform or services.
          <br />
          - Users agree that they use the platform at their own risk and accept
          full responsibility for their trading and investment outcomes.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          5. Subscription Terms
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - Subscriptions are non-refundable, non-cancellable, and
          non-transferable.
          <br />
          - Access to our platform is provided on a monthly basis. Subscriptions
          will expire automatically unless renewed by the user before the expiry
          date.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          6. Intellectual Property
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - All content, trademarks, and intellectual property on the platform
          are owned by us. Users may not reproduce, distribute, or misuse this
          content without prior written permission.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          7. Compliance with Laws
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - Users agree to comply with all applicable laws and regulations while
          using our platform.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          8. Amendments to Terms
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - We reserve the right to update these Terms at any time. Users are
          encouraged to review the Terms periodically for any changes.
          <br />
          - Continued use of the platform constitutes acceptance of any
          modifications to these Terms.
          <br />
          - Users also acknowledge and agree to the Terms and Conditions by accessing
          or using the platform, even if they do not hold an active subscription.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          9. Governing Law and Disputes
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - These Terms are governed by the laws of India. Any disputes arising
          from these Terms or the use of the platform will be subject to the
          exclusive jurisdiction of the courts in Bangalore, India.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          10. Contact Information
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          - If you have any questions or concerns regarding these Terms, please
          contact us through the support system of the portal.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
        11. Single-User Subscription
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
        - A subscription is granted to a single user for personal use only. Sharing
        of insights, data, or analytics with others, whether publicly or privately,
        is strictly prohibited.
        <br />
        - Publicly displaying insights or using them for group consumption, business
        purposes, or redistribution without prior written consent is a violation of
        these Terms and may result in legal action.
        <br />
        - Users found violating this policy will have their subscriptions terminated
        without refund and may face penalties as per applicable laws.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
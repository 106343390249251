/* global Razorpay */
import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../styles/Payment.css";
import { useAuth } from "../../AuthContext";
import { useNavigate } from 'react-router-dom';

const plans = [
  {
    id: 3,
    title: "30 Days Plan",
    price: "7,999",
    originalPrice: "",
    features: [
      "Nifty weekly expiry included",
      "Banknifty monthly expiry included",
      "Sensex weekly expiry included (coming soon)",
      "Finnifty monthly expiry included (coming soon)",
      "Access to Live insights with 1-minute frequency",
      "Access historical data",
      "Renew every month - manually",
      "Validity - 30 Days, including today",
      "For individual private use only - public displays, group, corporate use and live streaming not allowed"
    ],
    isPopular: false,
    allowCoupons: true,
  },
  {
    id: 1,
    title: "30 Days Plan",
    price: "14,999",
    originalPrice: "",
    features: [
      "Nifty weekly expiry included",
      "Banknifty monthly expiry included",
      "Sensex weekly expiry included (coming soon)",
      "Finnifty monthly expiry included (coming soon)",
      "Access to Live insights with 1-minute frequency",
      "Access historical data",
      "Renew every month - manually",
      "Validity - 30 Days, including today",
      "For individual private use only - public displays, group, corporate use and live streaming not allowed",
      "<strong>Markers</strong> &nbsp; Included (beta)"
    ],
    isPopular: true,
    allowCoupons: true,
  },
];

const faqs = [
  {
    question: "What is included in the subscription?",
    answer: "You will receive access to live Nifty and BankNifty analysis charts, covering over 40 options contracts that we actively track for weekly and monthly expiries.",
  },
  {
    question: "Can I cancel my subscription? Is there a refund policy?",
    answer: "The subscription is a non-refundable, non-cancellable, and non-transferable. Once purchased, payments cannot be refunded under any circumstances.",
  },
  {
    question: "Can I upgrade to a yearly subscription?",
    answer: "We are working on introducing additional subscription options, including yearly plans. Stay tuned for updates.",
  },
  {
    question: "How do I access my insights after payment?",
    answer: "Once your payment is confirmed and your subscription is activated, you will be automatically redirected to Live Nifty charts.",
  },
  {
    question: "Will my subscription be activated immediately after payment?",
    answer: "Yes. If everything goes well with the payment gateway, your subscription will be activated immediately."
  },
  {
    question: "How can I renew my subscription?",
    answer: "If you make a payment before your current subscription expires, the additional validity will be added after your current subscription’s end date, ensuring uninterrupted access."
  },
  {
    question: "It says my payment has failed, but amount is deducted from my account. What should I do?",
    answer: "Usually these payments are auto reverted within 7-15 working days. Reach out to us on support@915trade.com for any concerns."
  },
  {
    question: "What payment methods are allowed?",
    answer: "We have partnered with Razorpay for payment gateway. It allows most retail banks, cards, UPI and wallets. In case you need any particular payment option enabled, please email us at support@915trade.com."
  },
  ,
  {
    question: "What are markers?",
    answer: "Our system is designed to identify key areas of interest by analyzing all relevant data comprehensively. The primary goal is to simplify the analysis process for users, enabling them to focus on actionable insights. It is important to note that these markers are not Buy or Sell signals, nor does the platform provide any trading signals. Instead, the markers are intended to highlight potential areas that warrant further analysis. We strongly encourage traders to use these insights as a starting point for their own evaluations and to make informed trading decisions based on their personal analysis and judgment. Proper due diligence is essential to ensure trading decisions align with individual strategies and risk tolerances."
  },
];

const Payment = () => {
  const [agreements, setAgreements] = useState([false, false]);
  const [coupons, setCoupons] = useState(["", ""]);
  const [discountedPrice, setDiscountedPrice] = useState([null, null]);
  const [errorMessages, setErrorMessages] = useState(["", ""]);
  const [couponErrors, setCouponErrors] = useState(["", ""]);
  const [paymentError, setPaymentError] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState("");
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();

  const handleAgreementChange = (index) => {
    setAgreements((prev) => {
      const newAgreements = [...prev];
      newAgreements[index] = !prev[index];
      return newAgreements;
    });

    // Clear the error message when checkbox is toggled
    const newErrors = [...errorMessages];
    newErrors[index] = "";
    setErrorMessages(newErrors);
  };

  const handleCouponChange = (index, value) => {
    const newCoupons = [...coupons];
    newCoupons[index] = value;
    setCoupons(newCoupons);

    // Clear coupon error message
    const newCouponErrors = [...couponErrors];
    newCouponErrors[index] = "";
    setCouponErrors(newCouponErrors);
  };

  const handleApplyCoupon = async (index) => {
    try {
      const response = await axiosInstance.post("/verify-coupon-code", { coupon: coupons[index], planID: plans[index].id });

      if (response.data.status === "SUCCESS") {
        plans[index].originalPrice = plans[index].price;
        const newDiscountedPrice = [...discountedPrice];
        newDiscountedPrice[index] = response.data.discountedPrice;
        setDiscountedPrice(newDiscountedPrice);
      } else {
        const newCouponErrors = [...couponErrors];
        newCouponErrors[index] = response.data.message || "Invalid coupon code.";
        setCouponErrors(newCouponErrors);
      }
    } catch (error) {
      console.error("Error validating coupon:", error);
      const newCouponErrors = [...couponErrors];
      newCouponErrors[index] = "An error occurred while applying the coupon.";
      setCouponErrors(newCouponErrors);
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleRazorPayResponse = async (razorResponse) => {
    try {
      const data = {
        "paymentID": razorResponse.razorpay_payment_id,
        "orderID": razorResponse.razorpay_order_id
      }
      const response = await axiosInstance.post("/verify-payment", data);
      if (response.data.status === "success") {
        setPaymentSuccess("We received the payment confirmation. Thank you. Redirecting you to Live pages...")
        // Redirect after 4 seconds
        setTimeout(() => {
          navigate('/live-nifty-index');
        }, 4000);
      } else {
        setPaymentError("Something went wrong while processing your payment. Please email us at support@915trade.com in case you want to track the transaction. We will update the status of the transaction in a couple of days. Sorry for the inconvinience.")
      }
    } catch {
      console.error("Couldn't update the subscription details...");
      setPaymentError("Something went wrong while processing your payment. Please email us at support@915trade.com in case you want to track the transaction. We will update the status of the transaction in a couple of days. Sorry for the inconvinience.")
    }
  };

  const handlePayNow = async (index) => {
    if (!agreements[index]) {
      const newErrors = [...errorMessages];
      newErrors[index] = "Please read and select Terms and Conditions checkbox.";
      setErrorMessages(newErrors);
    } else {
      try {
        const isLoaded = await loadRazorpayScript();
        if(isLoaded) {
          const response = await axiosInstance.post("/prepare-payment", { planID: plans[index].id });
          if (response.data.status === "SUCCESS") {
            const newCouponErrors = [...couponErrors];
            newCouponErrors[index] = "";
            setCouponErrors(newCouponErrors);

            const options = response.data.data;
            options.handler = handleRazorPayResponse;
            const rzp1 = new Razorpay(options);
            rzp1.on("payment.failed", (response) => {
              const newCouponErrors = [...couponErrors];
              newCouponErrors[index] = "Unable to process payment with Payment Gateway at the moment.";
              setCouponErrors(newCouponErrors);
            });
            rzp1.open();
          }
        } else {
          const newCouponErrors = [...couponErrors];
          newCouponErrors[index] = "Unable to intiate payment with Payment Gateway at the moment.";
          setCouponErrors(newCouponErrors);
        }
      } catch {
        const newCouponErrors = [...couponErrors];
        newCouponErrors[index] = "Unable to intiate payment with Payment Gateway at the moment.";
        setCouponErrors(newCouponErrors);
      }
    }
  };

  return (
    <Box className="payment-container">
      <Typography variant="h4" className="payment-title">
        Choose Your Plan
      </Typography>
      {paymentSuccess && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {paymentSuccess}
        </Alert>
      )}
      {paymentError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {paymentError}
        </Alert>
      )}
      <Box sx={{ mt: 6 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={4}
          className="pricing-section"
        >
          {plans.map((plan, index) => (
            <Grid
              key={index}
              item
              xs={12}
              md={5}
              className={`pricing-box ${plan.isPopular ? "popular" : ""}`}
            >
              {plan.isPopular && (
                <Typography variant="caption" className="popular-badge">
                  Most Popular
                </Typography>
              )}
              <Typography variant="h5" className="pricing-title">
                {plan.title}
              </Typography>
              <Typography variant="h4" className="pricing-price">
                ₹ {discountedPrice[index] !== null ? discountedPrice[index] : plan.price}
                {plan.originalPrice && (
                  <Typography
                    component="span"
                    sx={{
                      textDecoration: "line-through",
                      marginLeft: "8px",
                      color: "grey",
                      fontSize: "1rem",
                    }}
                  >
                    ₹ {plan.originalPrice}
                  </Typography>
                )}
              </Typography>
              <ul className="pricing-features">
              {plan.features.map((feature, i) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: feature }}></li>
              ))}
              </ul>
              {plan.allowCoupons && (
                <Box sx={{ mt: 2, display: "flex", gap: 1, alignItems: "center" }}>
                  <TextField
                    label="Coupon Code"
                    variant="outlined"
                    size="small"
                    value={coupons[index]}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Regular expression to allow letters, numbers, underscores, dashes, and dots
                      const regex = /^[a-zA-Z0-9_.-]*$/;

                      if (value.length <= 50 && regex.test(value)) {
                        handleCouponChange(index, value);
                      }
                    }}
                    inputProps={{
                      maxLength: 50, // Hard limit at the input level
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleApplyCoupon(index)}
                    sx={{
                      backgroundColor: "#2196f3", // Slightly lighter blue
                      "&:hover": {
                        backgroundColor: "#1769aa", // Hover effect with a slightly darker blue
                      },
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              )}
              {couponErrors[index] ? (
                <Alert severity="error" sx={{ mt: 1 }}>
                  {couponErrors[index]}
                </Alert>
              ) : (
                discountedPrice[index] !== null && (
                  <Typography sx={{ color: "green", mt: 1 }}>
                    Coupon applied successfully!
                  </Typography>
                )
              )}
              <Box className="terms-section">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreements[index]}
                      onChange={() => handleAgreementChange(index)}
                      name={`termsCheckbox_${index}`}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" className="terms-text">
                      I agree to the{" "}
                      <a
                        href="/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="terms-link"
                      >
                        Terms and Conditions
                      </a>
                    </Typography>
                  }
                />
                {errorMessages[index] && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {errorMessages[index]}
                  </Alert>
                )}
                <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => handlePayNow(index)}
                  sx={{
                    backgroundColor: "#1976d2",
                    "&:hover": {
                      backgroundColor: "#155a9c", // Darker shade for hover effect
                    },
                  }}
                >
                  Pay Now
                </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        {/* Note about historical data */}
        <Typography
          variant="body2"
          sx={{
            color: "grey.600",
            fontStyle: "italic",
            textAlign: "center",
            mt: 4,
          }}
        >
          Access to historical data does not require a subscription. However, subscription plans provide access to real-time data for live insights.
        </Typography>
      </Box>

      {/* FAQs Section */}
      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" className="terms-link" sx={{ marginBottom: "20px" }}>
          Frequently Asked Questions
        </Typography>
        {faqs.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Payment;
import React, { useState, useEffect } from "react";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import StrikeBars from "../charts/StrikeBars";
import StrikeComparisonChart from "../charts/StrikeComparisonChart";
import { useAuth } from "../../AuthContext";
import { Autocomplete, TextField, Grid, Box, IconButton, Dialog, DialogTitle, DialogContent, Backdrop, CircularProgress, MenuItem, Select } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import InfoIcon from "@mui/icons-material/Info";

const NiftyIndexHistory = () => {
  const { axiosInstance } = useAuth();
  const [tradeDates, setTradeDates] = useState([]);
  const [selectedTradeDate, setSelectedTradeDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("15:29");
  const [lineData, setLineData] = useState(null);
  const [secondLineData, setSecondLineData] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const [strikesData, setStrikesData] = useState(null);
  const [strikeComparisonData, setStrikeComparisonData] = useState(null);
  const [maximizedCell, setMaximizedCell] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sellData, setSellData] = useState(null);
  const [strikeCandleData, setStrikeCandleData] = useState(null);
  const [clickedStrike, setClickedStrike] = useState(null);
  const [strikeDataLoading, setStrikeDataLoading] = useState(false);
  const [indications, setIndications] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState("Nifty"); // State for dropdown selection

  const computeSecondLineData = (data) => {
    if (!data || !data.CE || !data.PE) return null;

    return {
      ...data,
      CE: data.PE.map((peItem, index) => ({
        candleTime: peItem.candleTime,
        sell_volume: peItem.sell_volume - (data.CE[index]?.sell_volume || 0),
      })),
      PE: [],
    };
  };

  const fetchTradeDates = async (index) => {
    try {
      const response = await axiosInstance.post("/get-index-trade-dates", { index });
      const tradeDateOptions = response.data.map((item) => item.trade_date);
      setTradeDates(tradeDateOptions);
    } catch (error) {
      console.error("Error fetching trade dates:", error);
    }
  };

  useEffect(() => {
    if (selectedIndex) {
      fetchTradeDates(selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (!selectedTradeDate) {
      setLineData(null);
      setSecondLineData(null);
      setCandleData(null);
      setStrikesData(null);
      setStrikeComparisonData(null);
      return;
    }

    const fetchData = async () => {
      try {
        const lineResponse = await axiosInstance.post("/get-index-history-sell-data", {
          trade_date: selectedTradeDate,
          index: selectedIndex, // Pass selectedIndex to the API
        });
        const lineChartData = lineResponse.data;
        setLineData(lineChartData);
        setSecondLineData(computeSecondLineData(lineChartData));

        const candleResponse = await axiosInstance.post("/get-index-history-candles", {
          trade_date: selectedTradeDate,
          index: selectedIndex, // Pass selectedIndex to the API
        });
        setCandleData(candleResponse.data);

        const strikesResponse = await axiosInstance.post("/get-index-history-strike-buysell", {
          trade_date: selectedTradeDate,
          time: "15:29",
          index: selectedIndex, // Pass selectedIndex to the API
        });
        setStrikesData(strikesResponse.data);

        const strikeComparisonResponse = await axiosInstance.post("/get-index-history-strike-buypressure", {
          trade_date: selectedTradeDate,
          time: "15:29",
          index: selectedIndex, // Pass selectedIndex to the API
        });
        setStrikeComparisonData(strikeComparisonResponse.data);

        const indicationResponse = await axiosInstance.post("/get-indications", {
          trade_date: selectedTradeDate,
          index: selectedIndex, // Pass selectedIndex to the API
        });
        setIndications(indicationResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedTradeDate, selectedIndex, axiosInstance]);

  const handleTimeChange = async (newTime) => {
    setSelectedTime(newTime);
    if (!selectedTradeDate) return;

    try {
      const response = await axiosInstance.post("/get-index-history-strike-buysell", {
        trade_date: selectedTradeDate,
        time: newTime,
        index: selectedIndex, // Pass selectedIndex to the API
      });
      setStrikesData(response.data);

      const buyPressureResponse = await axiosInstance.post("/get-index-history-strike-buypressure", {
        trade_date: selectedTradeDate,
        time: newTime,
        index: selectedIndex, // Pass selectedIndex to the API
      });
      setStrikeComparisonData(buyPressureResponse.data);
    } catch (error) {
      console.error("Error fetching strikes data for selected time:", error);
    }
  };

  // Handle click for title and info icon
  const handleHelpClick = () => {
    window.open("/help/history-index", "_blank", "noopener,noreferrer");
  };

  const handleBarClick = async (strike) => {
    try {
      setStrikeDataLoading(true);
      const response = await axiosInstance.post(`/get-history-sell-data`, { trade_date: selectedTradeDate, strike: strike, index: selectedIndex });
      const response1 = await axiosInstance.post(`/get-history-candle-data`, { trade_date: selectedTradeDate, strike: strike, index: selectedIndex });
      setSellData(response.data);
      setStrikeCandleData(response1.data);
      setClickedStrike(strike);
      setStrikeDataLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching sell data:", error);
    }
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedIndex(value);
    setTradeDates([]);
    setSelectedTradeDate(null);
    setLineData(null);
    setSecondLineData(null);
    setCandleData(null);
    setStrikesData(null);
    setStrikeComparisonData(null);
    fetchTradeDates(value);
  };


  const handleMaximize = (cellId) => setMaximizedCell(cellId);
  const handleMinimize = () => setMaximizedCell(null);

  const renderCell = (cellId, content) => {
    const isMaximized = maximizedCell === cellId;

    // Static titles for each cell ID
    const titles = [
      null, // To align indices with cell IDs (1-based indexing)
      selectedIndex + " Buy & Sell Volume",
      selectedIndex + " Buy - Sell Volume",
      selectedIndex + " Spot Candles",
      selectedIndex + " Strike wise Sellers Volume",
      selectedIndex + " Strike wise Buyers Volume",
    ];

    return (
      <Box
        sx={{
          position: "relative",
          height: isMaximized ? "100vh" : "100%",
          width: "100%",
          backgroundColor: "#f5f5f5",
          border: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: isMaximized ? 1300 : "auto",
        }}
      >
        {titles[cellId] && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: cellId === 4 ? "200px" : "8px",
              zIndex: 1400,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: "0.9rem",
              fontWeight: "bold",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              cursor: "pointer", // Make the whole title clickable
            }}
            onClick={handleHelpClick} // Add click handler for title
          >
            {titles[cellId]}
            <InfoIcon
              sx={{
                fontSize: "1rem",
                marginLeft: "4px",
                cursor: "pointer",
                color: "#0B99FF",
              }}
            />
          </Box>
        )}
        <IconButton
          onClick={() =>
            isMaximized ? handleMinimize() : handleMaximize(cellId)
          }
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1400,
          }}
        >
          {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        {content}
      </Box>
    );
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden", minHeight: "calc(100vh - 120px)" }}>
      {/* Full-Screen Loader */}
      {strikeDataLoading && (
        <Backdrop
          open={true}
          sx={{
            zIndex: 2000, // Higher than other elements
            color: "#fff",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div style={{ display: "flex", alignItems: "center", gap: "20px", marginBottom: "20px", marginTop: "20px", marginLeft: "10px" }}>
        <Box
          sx={{
            position: "relative",
            overflow: "visible", // Ensure the dropdown isn't clipped
          }}
        >
          <Select
            value={selectedIndex}
            onChange={handleSelectChange}
            sx={{ minWidth: 150, zIndex: 1501 }}
            MenuProps={{
              PaperProps: {
                sx: {
                  zIndex: 1502,
                },
              },
            }}
          >
            <MenuItem value="Nifty">Nifty</MenuItem>
            <MenuItem value="Banknifty">Banknifty</MenuItem>
          </Select>
        </Box>

        <Autocomplete
          options={tradeDates}
          value={selectedTradeDate}
          onChange={(e, newValue) => {
            setSelectedTradeDate(newValue); // Update trade date
            setSelectedTime("15:29"); // Reset selected time
          }}
          renderInput={(params) => <TextField {...params} label="Select Trade Date" />}
          style={{ flex: 1 }}
          freeSolo={false}
          disableClearable
        />
      </div>

      <Grid container spacing={2}>
        {maximizedCell && (
          <Grid item xs={12}>
            {renderCell(
              maximizedCell,
              maximizedCell === 1
                ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                : maximizedCell === 2
                ? secondLineData && <LineChart data={secondLineData} legends={["Diff Volume"]} />
                : maximizedCell === 3
                ? candleData && <CandleChart data={candleData} indications={indications} />
                : strikesData && (
                    <StrikeBars
                      data={strikesData}
                      isHistorical={true}
                      onTimeChange={handleTimeChange}
                      selectedTime={selectedTime}
                      setSelectedTime={setSelectedTime}
                      onBarClick={(bar) => {
                        setStrikeDataLoading(true); // Start loader
                        handleBarClick(bar); // Handle bar click logic
                      }}
                    />
                  )
            )}
          </Grid>
        )}
        {[1, 2, 3, 4]
          .filter((id) => id !== maximizedCell)
          .map((id) => (
            <Grid item xs={6} key={id} sx={{ height: "50vh" }}>
              {renderCell(
                id,
                id === 1
                  ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                  : id === 2
                  ? secondLineData && <LineChart data={secondLineData} legends={["Diff Volume"]} />
                  : id === 3
                  ? candleData && <CandleChart data={candleData} indications={indications} />
                  : strikesData && (
                      <StrikeBars
                        data={strikesData}
                        isHistorical={true}
                        onTimeChange={handleTimeChange}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                        onBarClick={(bar) => {
                          setStrikeDataLoading(true); // Start loader
                          handleBarClick(bar); // Handle bar click logic
                        }}
                      />
                    )
              )}
            </Grid>
          ))}
        <Grid item xs={12} sx={{ height: "70vh" }}>
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              backgroundColor: "#f5f5f5",
              border: "1px solid #ddd",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "200px",
                zIndex: 1400,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "0.9rem",
                fontWeight: "bold",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                cursor: "pointer", // Make the whole title clickable
              }}
              onClick={handleHelpClick} // Add click handler for title
            >
              {selectedIndex} Strike wise Buyers chart
              <InfoIcon
                sx={{
                  fontSize: "1rem",
                  marginLeft: "4px",
                  cursor: "pointer",
                  color: "#0B99FF",
                }}
              />
            </Box>
            {strikeComparisonData && (
              <StrikeComparisonChart
                data={strikeComparisonData}
                onTimeChange={handleTimeChange}
                isHistorical={true}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
              />
            )}
          </Box>
        </Grid>
        <Dialog
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setStrikeDataLoading(false); // Stop loader after modal closes
          }}
          maxWidth="md"
          fullWidth
          sx={{ zIndex: 1500 }}
        >
          <DialogTitle>Strike Data - Strike:{clickedStrike}</DialogTitle>
          <DialogContent>
            {sellData && <LineChart data={sellData} legends={["Call", "Put"]} />}
            {/* Call Candle Chart */}
            {strikeCandleData?.CE && (
              <CandleChart
                data={strikeCandleData.CE}
                title="Call Candles"
              />
            )}

            {/* Put Candle Chart */}
            {strikeCandleData?.PE && (
              <CandleChart
                data={strikeCandleData.PE}
                title="Put Candles"
              />
            )}
          </DialogContent>
        </Dialog>
      </Grid>
    </div>
  );
};

export default NiftyIndexHistory;
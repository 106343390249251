import React from 'react';
import ReactECharts from 'echarts-for-react';

const LineChart = ({ data, legends = ['Call', 'Put'] }) => {
  const formatValue = (value) => {
    const absValue = Math.abs(value); // Handle negative values
    if (absValue >= 1_000_000) {
      const millions = absValue / 1_000_000;
      const formattedValue = millions % 1 === 0 ? `${millions}M` : `${millions.toFixed(1)}M`;
      return value < 0 ? `-${formattedValue}` : formattedValue;
    } else if (absValue >= 1_000) {
      const thousands = Math.floor(absValue / 1_000);
      return value < 0 ? `-${thousands}K` : `${thousands}K`;
    }
    return value.toString();
  };

  const getChartOptions = () => {
    const series = [];

    // Add the first line (if data is available)
    if (data.CE && data.CE.length) {
      series.push({
        name: legends[0],
        type: 'line',
        data: data.CE.map((item) => Math.floor(item.sell_volume)),
        smooth: true,
        lineStyle: {
          color: 'red',
        },
        itemStyle: {
          color: 'red',
        },
      });
    }

    // Add the second line (if data is available and legends[1] exists)
    if (data.PE && data.PE.length && legends[1]) {
      series.push({
        name: legends[1],
        type: 'line',
        data: data.PE.map((item) => Math.floor(item.sell_volume)),
        smooth: true,
        lineStyle: {
          color: 'blue',
        },
        itemStyle: {
          color: 'blue',
        },
      });
    }

    return {
      title: {
        text: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          // Extract the time from the x-axis
          const time = params[0]?.axisValue || ''; // Assuming all series have the same x-axis value
          const formattedTime = (() => {
            const date = new Date(time);
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          })();
      
          // Format the series values
          const seriesValues = params
            .map((item) => `${item.seriesName}: ${formatValue(item.value)}`)
            .join('<br/>');
      
          return `Time: ${formattedTime}<br/>${seriesValues}`;
        },
      },
      grid: {
        right: "7%",
        bottom: '13%', // Add extra padding at the bottom for the slider
        left: window.innerWidth <= 768 ? '12%' : '6%',
        top: '15%', // Add space for the legend
      },
      xAxis: {
        type: 'category',
        data: data.CE.map((item) => item.candleTime),
        axisLabel: {
          rotate: 45,
          formatter: (value) => {
            const date = new Date(value);
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'Volume',
        axisLabel: {
          formatter: formatValue,
        },
        min: Math.min(
          ...(data.CE || []).map((item) => item.sell_volume),
          ...(data.PE || []).map((item) => item.sell_volume)
        ),
        max: Math.max(
          ...(data.CE || []).map((item) => item.sell_volume),
          ...(data.PE || []).map((item) => item.sell_volume)
        ),
      },
      legend: {
        orient: 'horizontal', // Arrange legends horizontally
        top: 'top', // Position legends at the top
        left: 'center', // Center legends horizontally
        data: legends.slice(0, series.length), // Only show legends for available series
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          bottom: 10, // Adjust slider position
        },
        {
          type: 'inside',
        },
      ],
      toolbox: {
        show: false,
        feature: {
          dataZoom: {
            title: {
              zoom: 'Zoom In',
              back: 'Reset Zoom',
            },
            yAxisIndex: true,
          },
        },
      },
      series, // Dynamically generated series
    };
  };

  return <ReactECharts option={getChartOptions()} style={{ height: '600px', width: '100%' }} />;
};

export default LineChart;
import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { Autocomplete, TextField, Box, Typography } from "@mui/material";

const StrikeBars = ({
  data,
  onTimeChange,
  isHistorical,
  resetTime,
  onBarClick = () => {},
  selectedTime = "15:29", // Default value for selectedTime
  setSelectedTime = () => {}, // Default no-op function for setSelectedTime
}) => {
  const [chartData, setChartData] = useState([]);

  // Generate time frames from 9:15 to 15:29 at 1-minute intervals
  const generateTimeFrames = () => {
    const times = [];
    let hour = 9;
    let minute = 15;

    while (hour < 15 || (hour === 15 && minute < 30)) {
      times.push(`${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`);
      minute += 1;
      if (minute === 60) {
        hour += 1;
        minute = 0;
      }
    }
    return times;
  };

  const timeFrames = isHistorical ? generateTimeFrames() : [];

  useEffect(() => {
    if (resetTime) {
      setSelectedTime(resetTime); // Reset the dropdown to the default time
    }
  }, [resetTime]);

  useEffect(() => {
    if (data) {
      setChartData(processData(data));
    }
  }, [data]);

  const processData = (rawData) => {
    const validateVolume = (value) => {
      const numValue = Number(value); // Convert to number
      return isFinite(numValue) && !isNaN(numValue) && numValue >= 0 ? numValue : 0; // Ensure valid non-negative number
    };
  
    const atmStrike = {
      strike: rawData.atmStrike.strike,
      buy_volume: validateVolume(rawData.atmStrike.buy_volume),
      sell_volume: validateVolume(rawData.atmStrike.sell_volume),
      label: `ATM Strike: ${rawData.atmStrike.strike}`,
    };
  
    const lowerStrikes = rawData.lowerStrikes.map((strike) => ({
      strike: strike.strike,
      buy_volume: validateVolume(strike.buy_volume),
      sell_volume: validateVolume(strike.sell_volume),
      label: `Strike: ${strike.strike}`,
    }));
  
    const upperStrikes = rawData.upperStrikes.map((strike) => ({
      strike: strike.strike,
      buy_volume: validateVolume(strike.buy_volume),
      sell_volume: validateVolume(strike.sell_volume),
      label: `Strike: ${strike.strike}`,
    }));
  
    return [...upperStrikes.reverse(), atmStrike, ...lowerStrikes];
  };

  const formatVolume = (value) => {
    const absValue = Math.abs(value);
    if (absValue >= 1_000_000) {
      return `${(absValue / 1_000_000).toFixed(1)}M`;
    } else if (absValue >= 1_000) {
      return `${(absValue / 1_000).toFixed(1)}K`;
    }
    return value.toString();
  };

  const getTotals = () => {
    // Convert string to numbers during aggregation
    const buyTotal = chartData
      .filter((d) => isFinite(d.buy_volume))
      .reduce((sum, d) => {
        const volume = Number(d.buy_volume);
        if (!isFinite(volume)) console.warn("Invalid buy_volume detected:", d.buy_volume);
        return sum + volume;
      }, 0);
  
    const sellTotal = chartData
      .filter((d) => isFinite(d.sell_volume))
      .reduce((sum, d) => {
        const volume = Number(d.sell_volume);
        if (!isFinite(volume)) console.warn("Invalid sell_volume detected:", d.sell_volume);
        return sum + volume;
      }, 0);
  
    const diff = buyTotal - sellTotal;
  
    return {
      buyTotal: formatVolume(buyTotal),
      sellTotal: formatVolume(sellTotal),
      diff: `${diff > 0 ? "" : "-"}${formatVolume(Math.abs(diff))}`, // Keep sign for diff
    };
  };

  const { buyTotal, sellTotal, diff } = getTotals();

  const getChartOptions = () => ({
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      formatter: (params) => {
        if (params.length === 0) return ""; // Handle empty params
        
        const strike = params[0]?.data?.strike || "Unknown";
        const volumes = params
          .map((p) => `${p.seriesName}: ${formatVolume(p.value)}`)
          .join("<br>");
        
        return `Strike: ${strike}<br>${volumes}`;
      },
    },
    grid: { left: "20%", right: "20%", top: "10%", bottom: "10%" },
    xAxis: { type: "value", show: false },
    yAxis: {
      type: "category",
      inverse: true,
      data: chartData.map((d) => d.label),
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: { margin: 20 },
    },
    series: [
      {
        name: "Buy Volume",
        type: "bar",
        data: chartData.map((d) => ({ value: d.buy_volume, strike: d.strike })),
        barWidth: "40%",
        label: {
          show: true,
          position: "insideRight",
          formatter: (params) => formatVolume(params.value),
        },
        itemStyle: { color: "green" },
      },
      {
        name: "Sell Volume",
        type: "bar",
        data: chartData.map((d) => ({ value: d.sell_volume, strike: d.strike })),
        barWidth: "40%",
        label: {
          show: true,
          position: "insideRight",
          formatter: (params) => formatVolume(params.value),
        },
        itemStyle: { color: "red" },
      },
    ],
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        {isHistorical && (
          <Autocomplete
            options={timeFrames}
            value={selectedTime}
            onChange={(e, newValue) => {
              setSelectedTime(newValue);
              if (onTimeChange) onTimeChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Time" variant="outlined" size="small" />
            )}
            sx={{ flex: 1, maxWidth: "150px" }}
          />
        )}

        {/* Totals */}
        <Box
          sx={{
            marginLeft: isHistorical ? "16px" : "0px",
            paddingRight: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 1, // Ensures totals take remaining space if no dropdown
          }}
        >
          <Typography variant="body2" sx={{ fontSize: 12, textAlign: "right" }}>
            Buy: <b>{buyTotal}</b>, Sell: <b>{sellTotal}</b>, Diff: <b>{diff}</b>
          </Typography>
        </Box>
      </Box>
      <ReactECharts
        option={getChartOptions()}
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
        }}
        onEvents={{
          click: (params) => {
            if (params.data && params.data.strike) {
              // Trigger the callback with the strike value
              if (onBarClick) onBarClick(params.data.strike);
            }
          },
        }}
      />
    </Box>
  );
};

export default StrikeBars;
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null); // For sub-menus
  const [historyAnchor, setHistoryAnchor] = useState(null); // For History sub-menu

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  // Open sub-menu handlers
  const handleMenuOpen = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor) => {
    setAnchor(null);
  };

  const menuItems = auth.token
    ? [
        { text: "Pricing", onClick: () => navigate("/payment") },
        {
          text: "History Charts",
          subMenu: [
            { text: "Index View", onClick: () => navigate("/nifty-history") },
            { text: "Strike View", onClick: () => navigate("/nifty-strike-history") },
          ],
        },
        {
          text: "Live Charts",
          subMenu: [
            { text: "Nifty Index", onClick: () => navigate("/live-nifty-index") },
            { text: "BankNifty Index", onClick: () => navigate("/live-bank-nifty-index") },
            { text: "Nifty Strike View", onClick: () => navigate("/strike-data") },
          ],
        },
        { text: "Logout", onClick: handleLogout },
      ]
    : [
        { text: "Help", onClick: () => navigate("/help") },
        { text: "Register", onClick: () => navigate("/register") },
        { text: "Login", onClick: () => navigate("/login") },
      ];

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: "#0B99FF", padding: "0 16px", zIndex: 10 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Left: Logo */}
          <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => navigate("/")}>
            <img src="/915.gif" alt="Logo" style={{ height: "40px", marginRight: "10px" }} />
          </Box>

          {/* Right: Links for Desktop */}
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {menuItems.map((item, index) =>
              item.subMenu ? (
                <React.Fragment key={index}>
                  <Button
                    onClick={(e) => handleMenuOpen(e, item.text === "Live Charts" ? setMenuAnchor : setHistoryAnchor)}
                    sx={{
                      mx: 1,
                      textTransform: "none",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {item.text}
                  </Button>
                  <Menu
                    anchorEl={item.text === "Live Charts" ? menuAnchor : historyAnchor}
                    open={item.text === "Live Charts" ? Boolean(menuAnchor) : Boolean(historyAnchor)}
                    onClose={() => handleMenuClose(item.text === "Live Charts" ? setMenuAnchor : setHistoryAnchor)}
                    MenuListProps={{
                      sx: {
                        backgroundColor: "#1976d2", // Match header color
                        color: "white", // White text
                        borderRadius: "4px",
                      },
                    }}
                  >
                    {item.subMenu.map((subItem, subIndex) => (
                      <MenuItem
                        key={subIndex}
                        onClick={() => {
                          subItem.onClick();
                          handleMenuClose(item.text === "Live Charts" ? setMenuAnchor : setHistoryAnchor);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)", // Subtle hover effect
                          },
                        }}
                      >
                        {subItem.text}
                      </MenuItem>
                    ))}
                  </Menu>
                </React.Fragment>
              ) : (
                <Button
                  key={index}
                  onClick={item.onClick}
                  sx={{
                    mx: 1,
                    textTransform: "none",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {item.text}
                </Button>
              )
            )}
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile View */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <List sx={{ width: 250 }}>
          {menuItems.map((item, index) =>
            item.subMenu ? (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => {}}>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
                {item.subMenu.map((subItem, subIndex) => (
                  <ListItem key={`${index}-${subIndex}`} disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        subItem.onClick();
                        toggleDrawer(false);
                      }}
                    >
                      <ListItemText primary={subItem.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </React.Fragment>
            ) : (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => {
                    item.onClick();
                    toggleDrawer(false);
                  }}
                >
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
import React, { useState, useEffect, useRef } from "react";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import StrikeBars from "../charts/StrikeBars";
import BrokerBuyDiffChart from "../charts/BrokerBuyDiffChart";
import { Button, Grid, Box, IconButton, Drawer } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SimulationTradeMgmt from "./SimulationTradeMgmt";
import { useAuth } from "../../AuthContext";
import { v4 as uuidv4 } from "uuid";

const BrokerNiftyHistory = () => {
    const { axiosInstance } = useAuth();
    const [tradeDates, setTradeDates] = useState([]);
    const [selectedTradeDate, setSelectedTradeDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState("09:15");
    const [lineData, setLineData] = useState(null);
    const [secondLineData, setSecondLineData] = useState(null);
    const [candleData, setCandleData] = useState(null);
    const [strikesData, setStrikesData] = useState(null);
    const [strikeComparisonData, setStrikeComparisonData] = useState(null);
    const [strikeComparisonMoreData, setStrikeComparisonMoreData] = useState(null);
    const [maximizedCell, setMaximizedCell] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isNextDateDisabled, setIsNextDateDisabled] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // New state for drawer
    const [simulationId, setSimulationId] = useState(null);

    const [openOrders, setOpenOrders] = useState([]);
    const [completedTrades, setCompletedTrades] = useState([]);

    const playIntervalRef = useRef(null); // To manage the play interval
    const selectedTimeRef = useRef(selectedTime); // To keep track of the current time

    useEffect(() => {
        const newSimulationId = uuidv4();
        setSimulationId(newSimulationId);
    }, []);

    const computeSecondLineData = (data) => {
        if (!data || !data.CE || !data.PE) return null;

        return {
        ...data,
        CE: data.PE.map((peItem, index) => ({
            candleTime: peItem.candleTime,
            sell_volume: peItem.sell_volume - (data.CE[index]?.sell_volume || 0),
        })),
        PE: [],
        };
    };

    const fetchDataForTime = async (tradeDate, time) => {
        try {
        const lineResponse = await axiosInstance.post("/get-nifty-index-history-sell-data", {
            trade_date: tradeDate,
            time,
        });
        setLineData(lineResponse.data);
        setSecondLineData(computeSecondLineData(lineResponse.data));

        const candleResponse = await axiosInstance.post("/get-nifty-index-history-candles", {
            trade_date: tradeDate,
            time,
        });
        setCandleData(candleResponse.data);

        const strikesResponse = await axiosInstance.post("/get-nifty-index-history-strike-buysell", {
            trade_date: tradeDate,
            time,
        });
        setStrikesData(strikesResponse.data);

        const strikeComparisonResponse = await axiosInstance.post("/get-nifty-index-history-strike-buypressure", {
            trade_date: tradeDate,
            time,
        });
        setStrikeComparisonData(strikeComparisonResponse.data);

        const strikeComparisonMoreResponse = await axiosInstance.post("/get-nifty-index-history-strike-buypressure-more", {
            trade_date: tradeDate,
            time,
        });
        setStrikeComparisonMoreData(strikeComparisonMoreResponse.data);
        } catch (error) {
        console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const fetchTradeDates = async () => {
        try {
            const response = await axiosInstance.get("/get-nifty-index-trade-dates");
            const tradeDateOptions = response.data.map((item) => item.trade_date);
            const randomDates = tradeDateOptions.sort(() => 0.5 - Math.random()).slice(0, 5);
            setTradeDates(randomDates);
            setSelectedTradeDate(randomDates[0]);
            setSelectedTime("09:15");
            selectedTimeRef.current = "09:15"; // Initialize the ref
            fetchDataForTime(randomDates[0], "09:15");
        } catch (error) {
            console.error("Error fetching trade dates:", error);
        }
        };

        fetchTradeDates();
    }, [axiosInstance]);

    const handleNextCandle = () => {
        if (!selectedTradeDate || !selectedTimeRef.current) return;

        const [hours, minutes] = selectedTimeRef.current.split(":").map(Number);
        const newMinutes = (minutes + 1) % 60;
        const newHours = newMinutes === 0 ? hours + 1 : hours;

        const newTime = `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")}`;
        setSelectedTime(newTime);
        selectedTimeRef.current = newTime; // Update the ref
        fetchDataForTime(selectedTradeDate, newTime);
    };

    const handleNextDate = () => {
        const currentIndex = tradeDates.indexOf(selectedTradeDate);
        const nextIndex = (currentIndex + 1) % tradeDates.length;
        const nextDate = tradeDates[nextIndex];
        setSelectedTradeDate(nextDate);
        setSelectedTime("09:15");
        selectedTimeRef.current = "09:15"; // Reset the ref
        fetchDataForTime(nextDate, "09:15");

        // Disable the button if the next date is the last one
        if (currentIndex + 1 === tradeDates.length - 1) {
            setIsNextDateDisabled(true);
        }
    };

    const handlePlay = () => {
        if (isPlaying) return;

        setIsPlaying(true);

        playIntervalRef.current = setInterval(() => {
        handleNextCandle(); // Use the updated ref to calculate the next time
        }, 4000); // Load next candle every 2 seconds
    };

    const handlePause = () => {
        setIsPlaying(false);

        if (playIntervalRef.current) {
        clearInterval(playIntervalRef.current);
        playIntervalRef.current = null;
        }
    };

    const handleManageTrades = () => {
        setIsDrawerOpen(true); // Open the drawer
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false); // Close the drawer
    };

    const handleMaximize = (cellId) => setMaximizedCell(cellId);
    const handleMinimize = () => setMaximizedCell(null);

    const renderCell = (cellId, content) => {
        const isMaximized = maximizedCell === cellId;

    return (
        <Box
            sx={{
            position: "relative",
            height: isMaximized ? "100vh" : "100%",
            width: "100%",
            backgroundColor: "#f5f5f5",
            border: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: isMaximized && !isDrawerOpen ? 1300 : "auto",
            }}
        >
            <IconButton
            onClick={() =>
                isMaximized ? handleMinimize() : handleMaximize(cellId)
            }
            sx={{
                position: "absolute",
                top: 8,
                right: 8,
                zIndex: !isDrawerOpen ? 1400 : "auto",
            }}
            >
            {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            {content}
        </Box>
        );
    };

  return (
    <div style={{ width: "100%", overflowX: "hidden", minHeight: "calc(100vh - 120px)" }}>
      <Grid container spacing={2}>
        {maximizedCell && (
          <Grid item xs={12}>
            {renderCell(
              maximizedCell,
              maximizedCell === 1
                ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                : maximizedCell === 2
                ? secondLineData && <LineChart data={secondLineData} legends={["Diff Volume"]} />
                : maximizedCell === 3
                ? candleData && <CandleChart data={candleData} />
                : strikesData && <StrikeBars data={strikesData} isHistorical={false} />
            )}
          </Grid>
        )}
        {[1, 2, 3, 4]
          .filter((id) => id !== maximizedCell)
          .map((id) => (
            <Grid item xs={6} key={id} sx={{ height: "50vh" }}>
              {renderCell(
                id,
                id === 1
                  ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                  : id === 2
                  ? secondLineData && <LineChart data={secondLineData} legends={["Diff Volume"]} />
                  : id === 3
                  ? candleData && <CandleChart data={candleData} />
                  : strikesData && <StrikeBars data={strikesData} isHistorical={false} />
              )}
            </Grid>
          ))}
        <Grid item xs={12} sx={{ height: "70vh" }}>
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              backgroundColor: "#f5f5f5",
              border: "1px solid #ddd",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {strikeComparisonData && (
              <BrokerBuyDiffChart
                data={strikeComparisonData}
                onTimeChange={handleNextCandle}
                isHistorical={false}
                last10MinData={strikeComparisonMoreData}
              />
            )}
          </Box>
        </Grid>
      </Grid>

        {/* Fixed Button Banner */}
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(8px)",
                borderTop: "1px solid #ddd",
                padding: "16px",
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                zIndex: isDrawerOpen ? 1199 : 1200,
            }}
        >
            <Button variant="contained" color="primary" onClick={isPlaying ? handlePause : handlePlay}>
                {isPlaying ? "Pause" : "Play"}
            </Button>
            <Button variant="contained" color="secondary" onClick={handleNextCandle}>
                Next Candle
            </Button>
            <Button variant="contained" color="secondary" onClick={handleNextDate} disabled={isNextDateDisabled}>
                Next Date
            </Button>
            <Button variant="contained" color="default" onClick={handleManageTrades}>
                Manage Trades
            </Button>
        </Box>

      {/* Drawer for Manage Trades */}
        <Drawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}
            sx={{
                "& .MuiDrawer-paper": {
                    height: "70vh", // 70% of screen height
                    overflow: "hidden",
                },
        }}
        >
            <Box
                sx={{
                    height: "100%",
                    overflowY: "auto",
                    p: 2,
                }}
            >
                {/* Content inside the drawer */}
                <SimulationTradeMgmt tradeDate={selectedTradeDate} tradeTime={selectedTime} simulationID={simulationId} 
                    openOrders={openOrders} setOpenOrders={setOpenOrders} 
                    completedTrades={completedTrades} setCompletedTrades={setCompletedTrades} />
            </Box>
        </Drawer>
    </div>
  );
};

export default BrokerNiftyHistory;
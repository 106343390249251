import React, { useEffect } from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Register from './Register';
import ResetPassword from './ResetPassword';
import NiftyLive from './NiftyLive';
import NiftyLiveStrike from './NiftyLiveStrike';
import BankNiftyLive from './BankNiftyLive';
import IndexHistory from './IndexHistory';
import StrikeHistory from './StrikeHistory';
import Subscription from './Subscription';

const Help = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Get the selected content from the URL
  const selectedContentFromUrl = location.pathname.split('/')[2];

  // State to manage selected content
  const [selectedContent, setSelectedContent] = React.useState(selectedContentFromUrl || 'register');

  // Update the state and URL when a new section is selected
  const handleNavigation = (content) => {
    setSelectedContent(content);
    navigate(`/help/${content}`);
  };

  // Function to render the content based on selection
  const renderContent = () => {
    switch (selectedContent) {
      case 'register':
        return <Register />;
      case 'reset-password':
        return <ResetPassword />;
      case 'history-index':
        return <IndexHistory />;
      case 'history-strike':
        return <StrikeHistory />;
      case 'live-nifty':
        return <NiftyLive />;
      case 'live-banknifty':
        return <BankNiftyLive />;
      case 'live-strike':
        return <NiftyLiveStrike />;
      case 'buy-subscription':
        return <Subscription />;
      default:
        return <Register />;
    }
  };

  // Update the selectedContent state when the URL changes
  useEffect(() => {
    const contentFromUrl = location.pathname.split('/')[2];
    setSelectedContent(contentFromUrl || 'register');
  }, [location]);

  return (
    <Grid container sx={{ backgroundColor: '#ffffff' }}>
      {/* Left Menu */}
      <Grid
        item
        xs={3}
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#ffffff',
        }}
      >
        <Box sx={{ marginTop: '120px', marginLeft: '40px' }}>
          <List>
            <ListItem button onClick={() => handleNavigation('register')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="Register" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('reset-password')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="Reset Password" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('live-nifty')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="Live Charts - Nifty Index" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('live-strike')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="Live Charts - Nifty Strike View" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('live-banknifty')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="Live Charts - Banknifty Index" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('history-index')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="History Charts - Index View" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('history-strike')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="History Charts - Strike View" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('buy-subscription')} sx={{ padding: '2px 16px', marginBottom: '0px' }}>
              <ListItemText primary="Buy Subscription" sx={{ color: '#0B99FF', cursor: 'pointer' }} />
            </ListItem>
          </List>
        </Box>
      </Grid>

      {/* Main Content */}
      <Grid item xs={9} sx={{ padding: 3 }}>
        <Box>{renderContent()}</Box>
      </Grid>
    </Grid>
  );
};

export default Help;
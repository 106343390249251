// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: rgba(255, 0, 0, 0.8); /* Red with transparency */
    color: white;
    text-align: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/styles/UpgradeBanner.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM;IACN,aAAa;IACb,sCAAsC,EAAE,0BAA0B;IAClE,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;EACnB","sourcesContent":[".banner {\n    position: sticky;\n    top: 0;\n    z-index: 1000;\n    background-color: rgba(255, 0, 0, 0.8); /* Red with transparency */\n    color: white;\n    text-align: center;\n    padding: 10px 20px;\n    font-size: 14px;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Layout Styles */
body, html {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100%; /* Ensure the page takes up full height */
    display: flex;
    flex-direction: column;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height for the root container */
  }
  
  .main-content {
    flex: 1 1; /* Pushes the footer down */
    padding: 20px; /* Optional padding for the content */
  }
  
  /* Footer Styles */
  footer {
    background-color: #1976d2;
    color: white;
    text-align: center;
    padding: 20px;
    font-family: Roboto;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,+BAA+B;IAC/B,SAAS;IACT,UAAU;IACV,gBAAgB,EAAE,yCAAyC;IAC3D,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB,EAAE,gDAAgD;EACrE;;EAEA;IACE,SAAO,EAAE,2BAA2B;IACpC,aAAa,EAAE,qCAAqC;EACtD;;EAEA,kBAAkB;EAClB;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;EACrB","sourcesContent":["/* Layout Styles */\nbody, html {\n    font-family: Roboto, sans-serif;\n    margin: 0;\n    padding: 0;\n    min-height: 100%; /* Ensure the page takes up full height */\n    display: flex;\n    flex-direction: column;\n  }\n  \n  #root {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh; /* Full viewport height for the root container */\n  }\n  \n  .main-content {\n    flex: 1; /* Pushes the footer down */\n    padding: 20px; /* Optional padding for the content */\n  }\n  \n  /* Footer Styles */\n  footer {\n    background-color: #1976d2;\n    color: white;\n    text-align: center;\n    padding: 20px;\n    font-family: Roboto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

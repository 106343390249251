import React from 'react';
import ReactECharts from 'echarts-for-react';

const LineChartBuy = ({ data }) => {
  const getChartOptions = () => {
    return {
      title: {
        text: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        right: "7%",
        bottom: '13%',
        left: '5%',
      },
      xAxis: {
        type: 'category',
        data: data.CE.map((item) => item.candleTime),
        axisLabel: {
          rotate: 45,
          formatter: (value) => {
            const date = new Date(value);
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'Volume',
        axisLabel: {
          formatter: (value) => {
            if (value >= 1_000_000) {
              const millions = value / 1_000_000;
              return millions % 1 === 0 ? `${millions}M` : `${millions.toFixed(1)}M`;
            } else if (value >= 1_000) {
              return `${Math.floor(value / 1_000)}K`;
            }
            return value;
          },
        },
      },
      legend: {
        orient: 'vertical',
        right: 10,
        top: 'center',
        data: ['Call Sell', 'Call Buy', 'Put Sell', 'Put Buy'],
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          bottom: 10,
        },
        {
          type: 'inside',
        },
      ],
      toolbox: {
        show: false,
      },
      series: [
        {
          name: 'Call Sell',
          type: 'line',
          data: data.CE.map((item) => Math.floor(item.sell_volume)),
          smooth: true,
          lineStyle: {
            color: 'red',
            width: 2,
          },
          itemStyle: {
            color: 'red',
          },
        },
        {
          name: 'Call Buy',
          type: 'line',
          data: data.CE.map((item) => Math.floor(item.buy_volume)),
          smooth: true,
          lineStyle: {
            color: 'rgba(255, 0, 0, 0.3)', // Lighter red with transparency
            width: 1,
          },
          itemStyle: {
            color: 'rgba(255, 0, 0, 0.3)', // Match the line style color
          },
        },
        {
          name: 'Put Sell',
          type: 'line',
          data: data.PE.map((item) => Math.floor(item.sell_volume)),
          smooth: true,
          lineStyle: {
            color: 'blue',
            width: 2,
          },
          itemStyle: {
            color: 'blue',
          },
        },
        {
          name: 'Put Buy',
          type: 'line',
          data: data.PE.map((item) => Math.floor(item.buy_volume)),
          smooth: true,
          lineStyle: {
            color: 'rgba(0, 0, 255, 0.3)', // Lighter blue with transparency
            width: 1,
          },
          itemStyle: {
            color: 'rgba(0, 0, 255, 0.3)', // Match the line style color
          },
        },
      ],
    };
  };

  return <ReactECharts option={getChartOptions()} style={{ height: '600px', width: '100%' }} />;
};

export default LineChartBuy;

import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import PropTypes from "prop-types";

const CandleWithBars = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!data || !data.candles || !data.strikeData) {
      return;
    }

    const { candles, strikeData } = data;

    // Prepare candlestick data
    const candleSeries = candles.map((c) => [
      c.open,
      c.close,
      c.low,
      c.high
    ]);

    const candleTimes = candles.map((c) => c.candleTime);

    // Calculate line thickness groups
    const totalVolumes = strikeData.map((s) => s.buy_volume + s.sell_volume);
    const maxVolume = Math.max(...totalVolumes);
    const minVolume = Math.min(...totalVolumes);
    const groupRange = (maxVolume - minVolume) / 10;

    const getLineThickness = (volume) => {
      return Math.max(1, Math.ceil((volume - minVolume) / groupRange) * 1.5); // Reduce overall line thickness range
    };

    // Prepare horizontal lines with separation lines
    const lines = strikeData.flatMap((s) => {
      const totalVolume = s.buy_volume + s.sell_volume;
      const thickness = getLineThickness(totalVolume);
      const greenRatio = s.buy_volume / totalVolume;

      const greenEnd = greenRatio * (candleTimes.length - 1);

      return [
        {
          coords: [
            [candleTimes[0], s.strike], // Start point for green line
            [candleTimes[Math.round(greenEnd)], s.strike], // End point for green line
          ],
          lineStyle: {
            width: thickness,
            color: "#00b050", // Green line
            opacity: 0.3,
          },
        },
        {
          coords: [
            [candleTimes[Math.round(greenEnd)], s.strike], // Start point for red line
            [candleTimes[candleTimes.length - 1], s.strike], // End point for red line
          ],
          lineStyle: {
            width: thickness,
            color: "#ff0000", // Red line
            opacity: 0.5,
          },
        },
        {
          coords: [
            [candleTimes[Math.round(greenEnd)], s.strike], // Vertical separator line
            [candleTimes[Math.round(greenEnd)], s.strike],
          ],
          lineStyle: {
            width: 2, // Fixed thin width for separator
            color: "#E0E0E0", // Black line
          },
        },
      ];
    });

    // Initialize chart instance
    const chart = echarts.init(chartRef.current);

    // Configure chart options
    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        left: "5%",
        right: "5%",
        top: "15%",
        bottom: "10%", // Add space for zoom slider
      },
      xAxis: {
        type: "category",
        name: "",
        data: candleTimes,
        boundaryGap: true,
        axisLine: { onZero: false },
        splitLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          formatter: (value) => {
            const date = new Date(value);
            return `${date.getHours()}:${date.getMinutes()}`; // Format as HH:mm
          },
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        name: "",
        scale: true,
        min: Math.min(
          ...candles.map((c) => c.low),
          ...strikeData.map((s) => s.strike)
        ),
        max: Math.max(
          ...candles.map((c) => c.high),
          ...strikeData.map((s) => s.strike)
        ),
        axisLine: { onZero: false },
        splitLine: { show: true },
      },
      series: [
        ...lines.map((line) => ({
          type: "line",
          coordinateSystem: "cartesian2d",
          data: line.coords,
          lineStyle: line.lineStyle,
          symbol: "none",
        })),
        {
          name: "Candlestick",
          type: "candlestick",
          data: candleSeries,
          itemStyle: {
            color: "#00b050", // Bullish candle color
            color0: "#ff0000", // Bearish candle color
            borderColor: "#00b050",
            borderColor0: "#ff0000",
          },
          z: 10, // Ensure candles are on top
        },
        {
          type: "line",
          coordinateSystem: "cartesian2d",
          data: [
            [candleTimes[Math.floor(candleTimes.length / 2)], Math.min(
              ...candles.map((c) => c.low),
              ...strikeData.map((s) => s.strike)
            )],
            [candleTimes[Math.floor(candleTimes.length / 2)], Math.max(
              ...candles.map((c) => c.high),
              ...strikeData.map((s) => s.strike)
            )],
          ],
          lineStyle: {
            width: 2, // Thickness of the vertical line
            color: "#000000", // Black line
            type: "solid", // Solid line
          },
          symbol: "none", // No symbols at the ends of the line
        },
      ],
    };

    chart.setOption(option);

    // Cleanup on unmount
    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div ref={chartRef} style={{ width: "100%", height: "700px" }}></div>;
};

CandleWithBars.propTypes = {
  data: PropTypes.shape({
    candles: PropTypes.arrayOf(
      PropTypes.shape({
        candleTime: PropTypes.string.isRequired,
        open: PropTypes.number.isRequired,
        close: PropTypes.number.isRequired,
        high: PropTypes.number.isRequired,
        low: PropTypes.number.isRequired,
      })
    ).isRequired,
    strikeData: PropTypes.arrayOf(
      PropTypes.shape({
        strike: PropTypes.number.isRequired,
        buy_volume: PropTypes.number.isRequired,
        sell_volume: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default CandleWithBars;
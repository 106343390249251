import React from 'react';
import ReactECharts from 'echarts-for-react';

const SingleLineChart = ({ data, legend = 'Volume Difference', yAxisKey = 'diff'  }) => {
  const getChartOptions = () => {
    return {
      title: {
        text: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        right: '7%',
        bottom: '13%', // Add extra padding at the bottom for the slider
        left: window.innerWidth <= 768 ? '12%' : '6%',
        top: '15%', // Add space for the legend
      },
      xAxis: {
        type: 'category',
        data: data.map((item) => item.candleTime),
        axisLabel: {
          rotate: 45,
          formatter: (value) => {
            const date = new Date(value);
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'Volume Difference',
        axisLabel: {
          formatter: (value) => {
            const absValue = Math.abs(value);
            if (absValue >= 1_000_000) {
              const millions = absValue / 1_000_000;
              return `${millions.toFixed(1)}M`;
            } else if (absValue >= 1_000) {
              const thousands = absValue / 1_000;
              return `${thousands.toFixed(1)}K`;
            }
            return value;
          },
        },
        min: (value) => value.min, // Dynamically calculate the minimum
        max: (value) => value.max, // Dynamically calculate the maximum
      },
      legend: {
        orient: 'horizontal',
        top: 'top',
        left: 'center',
        data: [legend],
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          bottom: 10,
        },
        {
          type: 'inside',
        },
      ],
      toolbox: {
        show: false,
      },
      series: [
        {
          name: legend,
          type: 'line',
          data: data.map((item) => item[yAxisKey]),
          smooth: true,
          lineStyle: {
            color: 'green',
          },
          itemStyle: {
            color: 'green',
          },
        },
      ],
    };
  };

  return <ReactECharts option={getChartOptions()} style={{ height: '600px', width: '100%' }} />;
};

export default SingleLineChart;
import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import SellLines from "./components/pages/SellLines";
import Registration from "./components/pages/Registration";
import Login from "./components/pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "./AuthContext";
import LandingPage from "./components/pages/LandingPage";
import Payment from "./components/pages/Payment";
import Header from "./Header";
import SubscriptionRoute from "./components/pages/SubscriptionRoute";
import TermsAndConditions from "./TermsAndConditions";
import Footer from "./Footer";
import PrivacyPolicy from "./PrivacyPolicy";
import NiftyHistoryBuy from "./components/pages/NiftyHistoryBuy";
import NiftyIndexLive from "./components/pages/NiftyIndexLive";
import NiftyIndexHistory from "./components/pages/NiftyIndexHistory";
import NiftyHistosy from "./components/pages/NiftyHistory";
import UpgradeBanner from "./UpgradeBanner";
import KiteLogin from "./components/pages/KiteLogin";
import BankNiftyIndexLive from "./components/pages/BankNiftyIndexLive";
import BrokerNiftyHistory from "./components/pages/BrokerNiftyHistory";
import BrokerNiftyLive from "./components/pages/BrokerNiftyLive";
import EquityDashboard from "./components/pages/EquityDashboard";
import NiftySimulator from "./components/pages/NiftySimulator";
import Users from "./components/pages/Users";
import PasswordReset from "./components/pages/PasswordReset";
import NiftyIndexLiveMobile from "./components/pages/NiftyIndexLiveMobile";
import NIHNew from "./components/pages/NIHNew";
import Help from "./components/docs/Help";

const AppContent = () => {
  const location = useLocation();
  const bannerRoutes = ['/live-data', '/nifty-history', '/strike-data', '/nifty-strike-history', '/live-nifty-index', '/live-banknifty-index'];
  const showBanner = bannerRoutes.includes(location.pathname);

  return (
    <>
      <Header />
      {showBanner && <UpgradeBanner />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/help/:section?" element={<Help />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/kite-login" element={<ProtectedRoute allowedRoles={["admin"]}><KiteLogin /></ProtectedRoute>} />
        <Route path="/payment" element={<ProtectedRoute allowedRoles={["user", "admin"]}><Payment /></ProtectedRoute>} />

        <Route path="/live-nifty-index" element={<ProtectedRoute allowedRoles={["user", "admin"]}><SubscriptionRoute><NiftyIndexLive /></SubscriptionRoute></ProtectedRoute>} />
        <Route path="/live-bank-nifty-index" element={<ProtectedRoute allowedRoles={["user", "admin"]}><SubscriptionRoute><BankNiftyIndexLive /></SubscriptionRoute></ProtectedRoute>} />
        <Route path="/strike-data" element={<ProtectedRoute allowedRoles={["user", "admin"]}><SubscriptionRoute><SellLines /></SubscriptionRoute></ProtectedRoute>} />
        <Route path="/nifty-history" element={<ProtectedRoute allowedRoles={["user", "admin"]}><NiftyIndexHistory /></ProtectedRoute>} />
        <Route path="/nifty-strike-history" element={<ProtectedRoute allowedRoles={["user", "admin"]}><NiftyHistosy /></ProtectedRoute>} />
        <Route path="/nifty-history-buy" element={<ProtectedRoute allowedRoles={["user", "admin"]}><NiftyHistoryBuy /></ProtectedRoute>} />
        <Route path="/live-nifty-index-mobile" element={<ProtectedRoute allowedRoles={["user", "admin"]}><SubscriptionRoute><NiftyIndexLiveMobile /></SubscriptionRoute></ProtectedRoute>} />
        <Route path="/nih" element={<ProtectedRoute allowedRoles={["user", "admin"]}><NIHNew /></ProtectedRoute>} />

        <Route path="/nifty-history-v2" element={<ProtectedRoute allowedRoles={["user", "admin"]}><BrokerNiftyHistory /></ProtectedRoute>} />
        <Route path="/live-nifty-index-v2" element={<ProtectedRoute allowedRoles={["user", "admin"]}><BrokerNiftyLive /></ProtectedRoute>} />
        <Route path="/equity" element={<ProtectedRoute allowedRoles={["user", "admin"]}><EquityDashboard /></ProtectedRoute>} />
        <Route path="/nifty-simulator" element={<ProtectedRoute allowedRoles={["user", "admin"]}><NiftySimulator /></ProtectedRoute>} />

        <Route path="/users" element={<ProtectedRoute allowedRoles={["admin"]}><Users /></ProtectedRoute>} />

      </Routes>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;

import React, { useState, useEffect, useRef } from "react";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import StrikeBars from "../charts/StrikeBars";
import { useAuth } from "../../AuthContext";
import { Grid, Box, IconButton, Dialog, DialogContent, DialogTitle, CircularProgress, Backdrop } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import StrikeComparisonChart from "../charts/StrikeComparisonChart";
import InfoIcon from "@mui/icons-material/Info";

const NiftyIndexLive = () => {
  const { auth, axiosInstance } = useAuth();
  const [lineData, setLineData] = useState(null);
  const [secondLineData, setSecondLineData] = useState(null);
  const [strikesData, setStrikesData] = useState(null);
  const [maximizedCell, setMaximizedCell] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const [strikeComparisonData, setStrikeComparisonData] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState("Nifty");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sellData, setSellData] = useState(null);
  const [strikeCandleData, setStrikeCandleData] = useState(null);
  const [clickedStrike, setClickedStrike] = useState(null);
  const [strikeDataLoading, setStrikeDataLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [indications, setIndications] = useState([]);

  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const isMarketOpen = () => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const marketOpenMinutes = 9 * 60 + 15; // 9:15 AM
    const marketCloseMinutes = 15 * 60 + 30; // 3:30 PM
    return (
      currentMinutes >= marketOpenMinutes && currentMinutes < marketCloseMinutes
    );
  };

  const clearTimers = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const computeSecondLineData = (data) => {
    if (!data || !data.CE || !data.PE) return null;

    return {
      ...data,
      CE: data.PE.map((peItem, index) => ({
        candleTime: peItem.candleTime,
        sell_volume: peItem.sell_volume - data.CE[index]?.sell_volume || 0,
      })),
      PE: [],
    };
  };

  const handleBarClick = async (strike) => {
    try {
      setStrikeDataLoading(true);
      const response = await axiosInstance.post(`/get-sell-data`, { strike });
      const response1 = await axiosInstance.post(`/get-candle-data`, { strike });
      setSellData(response.data);
      setStrikeCandleData(response1.data);
      setClickedStrike(strike);
      setStrikeDataLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching sell data:", error);
    }
  };

  // Handle click for title and info icon
  const handleHelpClick = () => {
    window.open("/help/live-nifty", "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lineResponse = await axiosInstance.post(`/get-nifty-index-lines`, {index: "NIFTY"});
        const lineChartData = lineResponse.data;

        const candleResponse = await axiosInstance.post(`/get-nifty-index-candles`, {index: "NIFTY"});
        const candleChartData = candleResponse.data;

        const strikesResponse = await axiosInstance.post(`/get-nifty-nearest-strike-buysell`, {index: "NIFTY"});
        const strikesData = strikesResponse.data;

        const strikeComparisonResponse = await axiosInstance.post(`/get-nifty-nearest-strike-buypressure`, {index: "NIFTY"});
        const strikeComparisonData = strikeComparisonResponse.data;

        try {
          const indicationsResponse = await axiosInstance.post(`/get-index-live-indications`, {index: "nifty"});
          console.log(indicationsResponse.data);
          setIndications(indicationsResponse.data);
        } catch {
          //IGNORING the error assuming user does not have the markers subscription
        }

        setStrikesData(strikesData);
        setLineData(lineChartData);
        setCandleData(candleChartData);
        setStrikeComparisonData(strikeComparisonData);

        const computedData = computeSecondLineData(lineChartData);
        setSecondLineData(computedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataFetching(false);
      }
    };

    const fetchInitialDataAndStartTimer = async () => {
      await fetchData();

      if (isMarketOpen()) {
        const refreshData = async () => {
          if (isMarketOpen()) {
            await fetchData();
          } else {
            clearTimers();
          }
        };

        setDataFetching(true);
        const now = new Date();
        const targetSecond = 5; // Desired second of the minute
        const elapsed = now.getSeconds() * 1000 + now.getMilliseconds();
        let delay = (targetSecond * 1000) - elapsed;

        if (delay < 0) {
          // If we're already past the 5th second, calculate delay for the next minute
          delay += 60000;
        }

        timeoutRef.current = setTimeout(() => {
          refreshData();
          intervalRef.current = setInterval(refreshData, 60000);
        }, delay);
      } else {
        console.warn("Market is closed. Timer will not start.");
        setDataFetching(false);
      }
    };

    fetchInitialDataAndStartTimer();

    return clearTimers;
  }, [auth.token, axiosInstance]);

  const handleMaximize = (cellId) => {
    setMaximizedCell(cellId);
  };

  const handleMinimize = () => {
    setMaximizedCell(null);
  };

  const renderCell = (cellId, content) => {
    const isMaximized = maximizedCell === cellId;

    // Static titles for each cell ID
    const titles = [
      null, // To align indices with cell IDs (1-based indexing)
      "Nifty Buy & Sell Volume",
      "Nifty Buy - Sell Volume",
      "Nifty Spot Candles",
      "Nifty Strike wise Sellers Volume",
      "Nifty Strike wise Buyers Volume",
    ];

    return (
      <Box
        sx={{
          position: "relative",
          height: isMaximized ? "100vh" : "100%",
          width: "100%",
          backgroundColor: "#f5f5f5",
          border: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: isMaximized ? 1300 : "auto",
        }}
      >
        {titles[cellId] && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 8,
              zIndex: 1400,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: "0.9rem",
              fontWeight: "bold",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              cursor: "pointer", // Make the whole title clickable
            }}
            onClick={handleHelpClick} // Add click handler for title
          >
            {titles[cellId]}
            <InfoIcon
              sx={{
                fontSize: "1rem",
                marginLeft: "4px",
                cursor: "pointer",
                color: "#0B99FF",
              }}
            />
          </Box>
        )}
        <IconButton
          onClick={() =>
            isMaximized ? handleMinimize() : handleMaximize(cellId)
          }
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1400,
          }}
        >
          {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        {content}
      </Box>
    );
  };

  return (
    <>
      {/* Full-Screen Loader */}
      {strikeDataLoading && (
        <Backdrop
          open={true}
          sx={{
            zIndex: 2000, // Higher than other elements
            color: "#fff",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Grid
        container
        spacing={2}
        sx={{
          overflowY: maximizedCell ? "auto" : "hidden",
          flexDirection: "row",
          pointerEvents: strikeDataLoading ? "none" : "auto", // Disable interaction when loading
        }}
      >
        {maximizedCell && (
          <Grid item xs={12}>
            {renderCell(
              maximizedCell,
              maximizedCell === 1
                ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                : maximizedCell === 2
                ? secondLineData && (
                    <LineChart
                      data={secondLineData}
                      legends={["Diff Volume"]}
                    />
                  )
                : maximizedCell === 3
                ? candleData && <CandleChart data={candleData} indications={indications} />
                : strikesData && <StrikeBars data={strikesData} isHistorical={false} />
            )}
          </Grid>
        )}
        {[1, 2, 3, 4]
          .filter((id) => id !== maximizedCell)
          .map((id) => (
            <Grid
              item
              xs={6}
              key={id}
              sx={{
                height: "50vh",
              }}
            >
              {renderCell(
                id,
                id === 1
                  ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                  : id === 2
                  ? secondLineData && (
                      <LineChart
                        data={secondLineData}
                        legends={["Diff Volume"]}
                      />
                    )
                  : id === 3
                  ? candleData && <CandleChart data={candleData} indications={indications} />
                  : strikesData && (
                      <StrikeBars
                        data={strikesData}
                        isHistorical={false}
                        onBarClick={(bar) => {
                          setStrikeDataLoading(true); // Start loader
                          handleBarClick(bar); // Handle bar click logic
                        }}
                      />
                    )
              )}
            </Grid>
          ))}
        <Grid item xs={12} sx={{ height: "70vh" }}>
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              backgroundColor: "#f5f5f5",
              border: "1px solid #ddd",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 8,
                left: 8,
                zIndex: 1400,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "0.9rem",
                fontWeight: "bold",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
                cursor: "pointer",
              }}
              onClick={handleHelpClick}
              >
                Strike Wise Call & Put Buyers Volume
                <InfoIcon
                  sx={{
                    fontSize: "1rem",
                    marginLeft: "4px",
                    cursor: "pointer",
                    color: "#0B99FF",
                  }}
                />
            </Box>
            {strikeComparisonData && (
              <StrikeComparisonChart data={strikeComparisonData} isHistorical={false} />
            )}
          </Box>
        </Grid>

        <Dialog
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setStrikeDataLoading(false); // Stop loader after modal closes
          }}
          maxWidth="md"
          fullWidth
          sx={{ zIndex: 1500 }}
        >
          <DialogTitle>Strike Data - Strike:{clickedStrike}</DialogTitle>
          <DialogContent>
            {sellData && <LineChart data={sellData} legends={["Call", "Put"]} />}
            {/* Call Candle Chart */}
            {strikeCandleData?.CE && (
              <CandleChart
                data={strikeCandleData.CE}
                title="Call Candles"
              />
            )}
            
            {/* Put Candle Chart */}
            {strikeCandleData?.PE && (
              <CandleChart
                data={strikeCandleData.PE}
                title="Put Candles"
              />
            )}
          </DialogContent>
        </Dialog>
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 16, // Distance from the bottom
          right: 16, // Distance from the right
          zIndex: 2500, // Ensure it stays on top
          width: 16, // Dot size
          height: 16,
          borderRadius: "50%", // Make it a circle
          backgroundColor: dataFetching ? "green" : "red", // Green if fetching, red otherwise
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)", // Optional shadow for better visibility
          border: "2px solid white", // Optional border for contrast
        }}
        title={dataFetching ? "Fetching data" : "Idle"} // Tooltip for better UX
      />
    </>
  );
};

export default NiftyIndexLive;
import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, CircularProgress } from "@mui/material";
import EquityLineChart from "./EquityLineChart";
import CandleChart from "../charts/CandleChart";
import SingleLineChart from "../charts/SingleLineChart";
import { useAuth } from "../../AuthContext";

const EquityDashboard = () => {
  const { axiosInstance } = useAuth();
  const [companies, setCompanies] = useState([]);
  const [tradeDates, setTradeDates] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedTradeDate, setSelectedTradeDate] = useState("");
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch list of companies
    const fetchCompanies = async () => {
      try {
        const response = await axiosInstance.get("/equity-list-companies");
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, [axiosInstance]);

  const handleCompanyChange = async (event) => {
    const company = event.target.value;
    setSelectedCompany(company);
    setSelectedTradeDate(""); // Reset trade date
    setChartData(null); // Clear chart data

    if (company) {
      try {
        setLoading(true);
        const response = await axiosInstance.post("/equity-fetch-trade-dates", {
          company_name: company,
        });
        setTradeDates(response.data);
      } catch (error) {
        console.error("Error fetching trade dates:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTradeDateChange = async (event) => {
    const date = event.target.value;
    setSelectedTradeDate(date);
    setChartData(null);

    if (selectedCompany && date) {
      try {
        setLoading(true);
        const response = await axiosInstance.post("/equity-fetch-data", {
          company_name: selectedCompany,
          trade_date: date,
        });
        setChartData(response.data);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Dropdown Container */}
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <FormControl style={{ flex: 1 }}>
          <InputLabel>Company</InputLabel>
          <Select value={selectedCompany} onChange={handleCompanyChange}>
            {Array.isArray(companies) &&
              companies.map((item, index) => (
                <MenuItem key={index} value={item.company}>
                  {item.company}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
  
        {tradeDates.length > 0 && (
          <FormControl style={{ flex: 1 }}>
            <InputLabel>Trade Date</InputLabel>
            <Select value={selectedTradeDate} onChange={handleTradeDateChange}>
              {Array.isArray(tradeDates) &&
                tradeDates.map((item, index) => (
                  <MenuItem key={index} value={item.tradeDate}>
                    {item.tradeDate}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </div>
  
      {loading && <CircularProgress style={{ display: "block", margin: "20px auto" }} />}
  
      {chartData && (
        <>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <EquityLineChart data={chartData} legends={["Sell", "Buy"]} />
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <SingleLineChart data={chartData} legend = 'Volume Difference' yAxisKey = 'diffQuantity' />
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <CandleChart data={chartData} />
          </div>
        </>
      )}
    </div>
  );
};

export default EquityDashboard;
// Subscription.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const Subscription = () => {
  return (
    <Box sx={{ marginTop: '40px', padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        Subscription Process
      </Typography>
      <Box
        sx={{
          width: '150px',
          height: '4px',
          backgroundColor: '#1976d2',
          marginBottom: '20px',
        }}
      />

      {/* Content */}
      <Typography variant="body1" gutterBottom>
        To buy a subscription, you can click on Pricing link in Header menu.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1. The subscription page allows you to buy subscription to access premium features of the platform.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2. User has 2 plans to choose from. One is a 30 day plan and the other is 90 day plan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3. Both plans clearly highlight the features included and the duration covered
      </Typography>
      <Typography variant='body1' gutterBottom>
        4. If you have a coupon code, please enter the code in the Coupon Code box and click on Apply button.
      </Typography>
      <Typography variant='body1' gutterBottom>
        5. User will have to check the Terms and Conditions box, without that the subscription process will not start.
      </Typography>
      <Typography variant="body1" gutterBottom>
        6. We request users to read the Terms & Conditions everytime they buy a subscription as these terms are subject to change.
      </Typography>
      <Typography variant="body1" gutterBottom>
        7. You can then click on Pay Now button to open RazorPay payment window which allows you to make payments using multiple options.
      </Typography>
      <Typography variant="body1" gutterBottom>
        8. Once the payment is successful, the screen will display the success message and redirect user automatically to the Live Insights page.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Important:</strong> If the payment fails, and if the amount is deducted from your account, a refund will be initiated automatically. We request users to email us at support@915trade.com for any payment related concerns.
      </Typography>
      <Typography variant="body1" gutterBottom>
        
      </Typography>
    </Box>
  );
};

export default Subscription;
import React, { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import CandleWithBars from "../charts/CandleWithBars";

const NIHNew = () => {
  const { axiosInstance } = useAuth();
  const [candleData, setCandleData] = useState(null);

  useEffect(() => {
    const fetchTradeDates = async () => {
      try {
        const response = await axiosInstance.post("/get-nifty-candles-bars", {"index":"NIFTY"});
        const tradeDateOptions = response.data;
        setCandleData(tradeDateOptions);
      } catch (error) {
        console.error("Error fetching trade dates:", error);
      }
    };

    fetchTradeDates();
  }, [axiosInstance]);

  return (
    <div style={{ width: "100%" }}>
      <CandleWithBars data={candleData} />
    </div>
  );
};

export default NIHNew;
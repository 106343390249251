import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import "../../styles/Registration.css";
import { baseAPIURL } from "../../Config";
import MathCaptcha from "./MathCaptcha";

const Registration = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptedTerms: false,
  });

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isOtpStep, setIsOtpStep] = useState(false);
  const [otpData, setOtpData] = useState({ otp: "", phone: "" });
  const [otpMessage, setOtpMessage] = useState("");
  const [captchaValidated, setCaptchaValidated] = useState(false);

  const validate = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required.";
    if (!phoneRegex.test(formData.phone)) newErrors.phone = "Phone number must be exactly 10 digits.";
    if (!emailRegex.test(formData.email)) newErrors.email = "Invalid email format.";
    if (!passwordRegex.test(formData.password))
      newErrors.password = "Password must be at least 8 characters, with at least 1 digit and 1 special character.";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";
    if (!formData.acceptedTerms)
      newErrors.acceptedTerms = "You must accept the Terms of Use and Privacy Policy.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCaptchaValidation = (isValid) => {
    setCaptchaValidated(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError("");
    if (!validate()) return;

    if (!captchaValidated) {
      setApiError("Please solve captcha correctly.");
      return;
    }

    try {
      setSubmitLoader(true);
      const response = await fetch(`${baseAPIURL}/user-register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (result.status === "success") {
        setOtpData({ phone: formData.phone, otp: "", email: formData.email });
        setIsOtpStep(true);
      } else {
        setApiError(result.reason || "Unable to complete registration at the moment.");
      }
    } catch (error) {
      setApiError("Unable to complete registration at the moment.");
    } finally {
      setSubmitLoader(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setApiError("");
    if (!/^\d{6}$/.test(otpData.otp)) {
      setErrors({ otp: "Please enter a valid 6-digit OTP." });
      return;
    }

    try {
      setSubmitLoader(true);
      const response = await fetch(`${baseAPIURL}/verify-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone: otpData.phone, otp: otpData.otp }),
      });
      const result = await response.json();

      if (result.status === "success") {
        setTimeout(() => (window.location.href = "/login"), 1000);
      } else {
        setApiError(result.reason || "OTP verification failed. Please try again.");
      }
    } catch (error) {
      setApiError("Unable to verify OTP at the moment.");
    } finally {
      setSubmitLoader(false);
    }
  };

  const handleResendOtp = async () => {
    setOtpMessage("");
    setApiError("");

    try {
      setSubmitLoader(true);
      const response = await fetch(`${baseAPIURL}/resend-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone: otpData.phone }),
      });
      const result = await response.json();

      if (result.status === "success") {
        setOtpData({ phone: otpData.phone, otp: "", email: otpData.email });
        setOtpMessage("A new OTP has been sent to your email ID.");
      } else {
        setApiError("Unable to send OTP at the moment.");
      }
    } catch (error) {
      setApiError("Unable to send OTP at the moment.");
    } finally {
      setSubmitLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (isOtpStep && name === "otp") {
      // Allow only numeric and max 6 digits for OTP
      if (/^\d*$/.test(value) && value.length <= 6) {
        setOtpData({ ...otpData, otp: value });
      }
    } else {
      setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    }
  };

  return (
    <Box>
      {/* Loader */}
      <Backdrop open={submitLoader} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Body */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "80vh", px: 3 }}
      >
        {/* Infographic Section */}
        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/registration.svg"
            alt="Infographic"
            style={{ width: "100%", borderRadius: "10px" }}
          />
        </Grid>
  
        {/* Form Section */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isOtpStep ? (
            <>
              {/* Registration Form */}
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  color: "#1976d2",
                  fontWeight: "bold",
                  fontFamily: "'Roboto', sans-serif",
                  textAlign: "center",
                }}
              >
                Register
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: 2,
                }}
              >
                {[
                  { label: "Full Name", name: "fullName", type: "text" },
                  { label: "Phone Number", name: "phone", type: "text" },
                  { label: "Email ID", name: "email", type: "email" },
                  { label: "Password", name: "password", type: "password" },
                  { label: "Confirm Password", name: "confirmPassword", type: "password" },
                ].map(({ label, name, type }) => (
                  <TextField
                    key={name}
                    label={label}
                    name={name}
                    type={type}
                    variant="outlined"
                    value={formData[name]}
                    onChange={handleChange}
                    required
                    fullWidth
                    error={!!errors[name]}
                    helperText={errors[name]}
                  />
                ))}

                {/* Math CAPTCHA */}
                <MathCaptcha onValidate={handleCaptchaValidation} />
  
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptedTerms"
                      checked={formData.acceptedTerms}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I have read and agree to the{" "}
                      <Link href="/privacy-policy" target="_blank" rel="noopener">
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                        Terms of Use
                      </Link>
                    </Typography>
                  }
                />
                {errors.acceptedTerms && (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", textAlign: "center" }}
                  >
                    {errors.acceptedTerms}
                  </Typography>
                )}
  
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#1976d2", color: "#fff" }}
                >
                  Register
                </Button>
                {apiError && (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", mt: 1, textAlign: "center" }}
                  >
                    {apiError}
                  </Typography>
                )}
              </Box>
            </>
          ) : (
            <>
              {/* OTP Verification */}
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: "#1976d2",
                  fontWeight: "bold",
                  fontFamily: "'Roboto', sans-serif",
                  textAlign: "center",
                }}
              >
                Verify OTP
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", mb: 2 }}
              >
                An OTP has been sent to your email ({otpData.email}).
                Please enter it below to verify your account.
              </Typography>
  
              {/* OTP Message */}
              {otpMessage && (
                <Typography
                  variant="body2"
                  sx={{ textAlign: "center", color: "green", mb: 2 }}
                >
                  {otpMessage}
                </Typography>
              )}
  
              <Box
                component="form"
                onSubmit={handleOtpSubmit}
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <TextField
                  label="OTP"
                  name="otp"
                  type="text"
                  variant="outlined"
                  value={otpData.otp}
                  onChange={(e) => setOtpData({ ...otpData, otp: e.target.value })}
                  required
                  fullWidth
                  error={!!errors.otp}
                  helperText={errors.otp}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#1976d2", color: "#fff" }}
                >
                  Verify OTP
                </Button>
                {apiError && (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", mt: 1, textAlign: "center" }}
                  >
                    {apiError}
                  </Typography>
                )}
              </Box>
  
              {/* Resend OTP */}
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  mt: 2,
                  color: "#1976d2",
                  cursor: "pointer",
                }}
                onClick={handleResendOtp}
              >
                Resend OTP
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Registration;
import axios from "axios";
import { baseAPIURL } from "./Config";

const createAxiosInstance = (logout) => {
  const instance = axios.create({
    baseURL: baseAPIURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Request Interceptor: Attach Authorization header
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token"); // Retrieve the token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // Response Interceptor: Handle 401 errors
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        console.error("Token expired or invalid. Logging out.");
        if (logout) logout(); // Call the logout function
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;
import React, { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "../../styles/Users.css"; // Include CSS for animations

const Users = () => {
  const { axiosInstance } = useAuth();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [newSubscription, setNewSubscription] = useState({
    startDate: "",
    endDate: "",
  });
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [archivingUserId, setArchivingUserId] = useState(null);

  const today = new Date().toISOString().split("T")[0];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch users
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get("/list-users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Fetch subscription
  const fetchSubscription = async (userId) => {
    try {
      const response = await axiosInstance.post(`/fetch-subscriptions`, {
        userID: userId,
      });
      setSubscriptions(response.data);
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  };

  // Handle user search
  const handleSearch = async () => {
    try {
      const response = await axiosInstance.post(`/find-user`, {
        term: searchTerm,
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };

  // Archive user
  const archiveUser = async (userId) => {
    setArchivingUserId(userId); // Highlight the row being archived
    try {
      const response = await axiosInstance.post(`/archive-user`, {
        userID: userId,
      });
      if (response.data.status === "SUCCESS") {
        setTimeout(() => {
          fetchUsers();
          setArchivingUserId(null); // Clear the highlight
        }, 500); // Matches the animation duration
      } else {
        alert("Failed to archive user");
        setArchivingUserId(null);
      }
    } catch (error) {
      console.error("Error archiving user:", error);
      setArchivingUserId(null);
    }
  };

  // Add subscription
  const addSubscription = async (userId) => {
    if (!newSubscription.startDate || !newSubscription.endDate) return;
    try {
      await axiosInstance.post(`/add-subscription`, {
        userID: userId,
        startDate: newSubscription.startDate,
        endDate: newSubscription.endDate,
      });
      fetchSubscription(userId);
      setNewSubscription({ startDate: "", endDate: "" }); // Reset form
    } catch (error) {
      console.error("Error adding subscription:", error);
    }
  };

  // Delete subscription
  const deleteSubscription = async (subscriptionId) => {
    // Temporarily remove the subscription for smooth fade-out
    setSubscriptions((prevSubscriptions) =>
      prevSubscriptions.filter((sub) => sub.subID !== subscriptionId)
    );

    try {
      const response = await axiosInstance.post(`/delete-subscription`, {
        subscriptionID: subscriptionId,
      });
      if (response.data.status === "SUCCESS") {
        setTimeout(() => {
          fetchSubscription(selectedUser.userID); // Re-fetch after animation
        }, 500); // Matches the fade-out animation duration
      } else {
        alert("Failed to delete subscription");
        fetchSubscription(selectedUser.userID); // Re-fetch to restore if deletion failed
      }
    } catch (error) {
      console.error("Error deleting subscription:", error);
      fetchSubscription(selectedUser.userID); // Re-fetch to restore if there's an error
    }
  };

  // Handle user click
  const handleUserClick = (user) => {
    if (archivingUserId) return; // Prevent interaction while archiving
    setSelectedUser(user);
    fetchSubscription(user.userID);
    setSubscriptionDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSubscriptionDialogOpen(false);
    setSelectedUser(null);
  };

  // Disable the Add Subscription button if dates are invalid
  const isAddSubscriptionDisabled =
    !newSubscription.startDate ||
    !newSubscription.endDate ||
    new Date(newSubscription.endDate) <= new Date(newSubscription.startDate);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      {/* Search Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <TextField
          label="Search by name or phone"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth={isMobile}
          onClick={handleSearch}
        >
          Search
        </Button>
      </Box>

      {/* User Table */}
      <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Email Verified</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.userID}
                className={archivingUserId === user.userID ? "archiving" : ""}
                hover
                onClick={() => handleUserClick(user)}
                style={{ cursor: "pointer" }}
              >
                <TableCell>{user.fullName}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.createdAt}</TableCell>
                <TableCell>{user.emailVerified === 1 ? "Yes" : "No"}</TableCell>
                <TableCell
                  onClick={(e) => e.stopPropagation()} // Prevent row click from triggering
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => archiveUser(user.userID)}
                  >
                    Archive
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Subscription Dialog */}
      {selectedUser && (
        <Dialog
          open={subscriptionDialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="sm"
          fullScreen={isMobile}
        >
          <DialogTitle>
            Subscriptions for {selectedUser.fullName}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {subscriptions.map((sub) => (
                <Grid item xs={12} sm={6} key={sub.subID}>
                  <Paper sx={{ p: 2 }}>
                    <Typography>
                      <strong>Pack:</strong> {sub.pack}
                    </Typography>
                    <Typography>
                      <strong>Start Date:</strong> {sub.startDate}
                    </Typography>
                    <Typography>
                      <strong>End Date:</strong> {sub.endDate}
                    </Typography>
                    <Typography>
                      <strong>Amount Received:</strong> {sub.amountReceived}
                    </Typography>
                    <Typography>
                      <strong>PaymentID:</strong> {sub.pgID}
                    </Typography>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => deleteSubscription(sub.subID)}
                      sx={{ mt: 1 }}
                    >
                      Delete Subscription
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>

            {/* Add Subscription Section */}
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Add New Subscription
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={newSubscription.startDate}
                    onChange={(e) =>
                      setNewSubscription({
                        ...newSubscription,
                        startDate: e.target.value,
                      })
                    }
                    inputProps={{ min: today }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="End Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={newSubscription.endDate}
                        onChange={(e) =>
                        setNewSubscription({
                            ...newSubscription,
                            endDate: e.target.value,
                        })
                        }
                        inputProps={{
                        min: newSubscription.startDate || today, // Ensure the end date cannot be earlier than the start date or today
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => addSubscription(selectedUser.userID)}
                    disabled={isAddSubscriptionDisabled}
                  >
                    Add Subscription
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Users;
import React from 'react';
import './styles/UpgradeBanner.css';

const UpgradeBanner = () => {
  //return (
  //  <div className="banner">
  //    Our systems are undergoing a major upgrade. You might face disruptions. 
  //    We deeply regret any inconvenience. Unfortunately, this had to be done during market hours.
  //  </div>
  //);
  return null;
};

export default UpgradeBanner;
import React, { useState, useEffect } from "react";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import StrikeBars from "../charts/StrikeBars";
import BrokerBuyDiffChart from "../charts/BrokerBuyDiffChart";
import { useAuth } from "../../AuthContext";
import { Autocomplete, TextField, Grid, Box, IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const BrokerNiftyHistory = () => {
  const { axiosInstance } = useAuth();
  const [tradeDates, setTradeDates] = useState([]);
  const [selectedTradeDate, setSelectedTradeDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("15:29");
  const [lineData, setLineData] = useState(null);
  const [secondLineData, setSecondLineData] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const [strikesData, setStrikesData] = useState(null);
  const [strikeComparisonData, setStrikeComparisonData] = useState(null);
  const [strikeComparisonMoreData, setStrikeComparisonMoreData] = useState(null);
  const [maximizedCell, setMaximizedCell] = useState(null);

  const computeSecondLineData = (data) => {
    if (!data || !data.CE || !data.PE) return null;

    return {
      ...data,
      CE: data.PE.map((peItem, index) => ({
        candleTime: peItem.candleTime,
        sell_volume: peItem.sell_volume - (data.CE[index]?.sell_volume || 0),
      })),
      PE: [],
    };
  };

  useEffect(() => {
    const fetchTradeDates = async () => {
      try {
        const response = await axiosInstance.get("/get-nifty-index-trade-dates");
        const tradeDateOptions = response.data.map((item) => item.trade_date);
        setTradeDates(tradeDateOptions);
      } catch (error) {
        console.error("Error fetching trade dates:", error);
      }
    };

    fetchTradeDates();
  }, [axiosInstance]);

  useEffect(() => {
    if (!selectedTradeDate) {
      setLineData(null);
      setSecondLineData(null);
      setCandleData(null);
      setStrikesData(null);
      setStrikeComparisonData(null);
      return;
    }

    const fetchData = async () => {
      try {
        const lineResponse = await axiosInstance.post("/get-nifty-index-history-sell-data", { trade_date: selectedTradeDate });
        const lineChartData = lineResponse.data;
        setLineData(lineChartData);
        setSecondLineData(computeSecondLineData(lineChartData));

        const candleResponse = await axiosInstance.post("/get-nifty-index-history-candles", { trade_date: selectedTradeDate });
        setCandleData(candleResponse.data);

        const strikesResponse = await axiosInstance.post("/get-nifty-index-history-strike-buysell", {
          trade_date: selectedTradeDate,
          time: "15:29",
        });
        setStrikesData(strikesResponse.data);

        const strikeComparisonResponse = await axiosInstance.post("/get-nifty-index-history-strike-buypressure", {
          trade_date: selectedTradeDate,
          time: "15:29",
        });
        setStrikeComparisonData(strikeComparisonResponse.data);

        const strikeComparisonMoreResponse = await axiosInstance.post("/get-nifty-index-history-strike-buypressure-more", {
          trade_date: selectedTradeDate,
          time: "15:29",
        });
        setStrikeComparisonMoreData(strikeComparisonMoreResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedTradeDate, axiosInstance]);

  const handleTimeChange = async (newTime) => {
    setSelectedTime(newTime);
    if (!selectedTradeDate) return;

    try {
      const response = await axiosInstance.post("/get-nifty-index-history-strike-buysell", {
        trade_date: selectedTradeDate,
        time: newTime,
      });
      setStrikesData(response.data);

      const buyPressureResponse = await axiosInstance.post("/get-nifty-index-history-strike-buypressure", {
        trade_date: selectedTradeDate,
        time: newTime,
      });
      setStrikeComparisonData(buyPressureResponse.data);

      const strikeComparisonMoreResponse = await axiosInstance.post("/get-nifty-index-history-strike-buypressure-more", {
        trade_date: selectedTradeDate,
        time: newTime,
      });
      setStrikeComparisonMoreData(strikeComparisonMoreResponse.data);
    } catch (error) {
      console.error("Error fetching strikes data for selected time:", error);
    }
  };

  const handleMaximize = (cellId) => setMaximizedCell(cellId);
  const handleMinimize = () => setMaximizedCell(null);

  const renderCell = (cellId, content) => {
    const isMaximized = maximizedCell === cellId;

    return (
      <Box
        sx={{
          position: "relative",
          height: isMaximized ? "100vh" : "100%",
          width: "100%",
          backgroundColor: "#f5f5f5",
          border: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: isMaximized ? 1300 : "auto",
        }}
      >
        <IconButton
          onClick={() =>
            isMaximized ? handleMinimize() : handleMaximize(cellId)
          }
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1400,
          }}
        >
          {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        {content}
      </Box>
    );
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden", minHeight: "calc(100vh - 120px)" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "20px", marginBottom: "20px", marginTop: "20px" }}>
        <h1 style={{ margin: 0 }}>Nifty Index History</h1>
        <Autocomplete
          options={tradeDates}
          value={selectedTradeDate}
          onChange={(e, newValue) => setSelectedTradeDate(newValue)}
          renderInput={(params) => <TextField {...params} label="Select Trade Date" />}
          style={{ flex: 1 }}
          freeSolo={false}
          disableClearable
        />
      </div>

      <Grid container spacing={2}>
        {maximizedCell && (
          <Grid item xs={12}>
            {renderCell(
              maximizedCell,
              maximizedCell === 1
                ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                : maximizedCell === 2
                ? secondLineData && <LineChart data={secondLineData} legends={["Diff Volume"]} />
                : maximizedCell === 3
                ? candleData && <CandleChart data={candleData} />
                : strikesData && (
                    <StrikeBars
                      data={strikesData}
                      isHistorical={true}
                      onTimeChange={handleTimeChange}
                    />
                  )
            )}
          </Grid>
        )}
        {[1, 2, 3, 4]
          .filter((id) => id !== maximizedCell)
          .map((id) => (
            <Grid item xs={6} key={id} sx={{ height: "50vh" }}>
              {renderCell(
                id,
                id === 1
                  ? lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />
                  : id === 2
                  ? secondLineData && <LineChart data={secondLineData} legends={["Diff Volume"]} />
                  : id === 3
                  ? candleData && <CandleChart data={candleData} />
                  : strikesData && (
                      <StrikeBars
                        data={strikesData}
                        isHistorical={true}
                        onTimeChange={handleTimeChange}
                        resetTime="15:29"
                      />
                    )
              )}
            </Grid>
          ))}
        <Grid item xs={12} sx={{ height: "70vh" }}>
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              backgroundColor: "#f5f5f5",
              border: "1px solid #ddd",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {strikeComparisonData && (
              <BrokerBuyDiffChart
                data={strikeComparisonData}
                onTimeChange={handleTimeChange}
                isHistorical={true}
                last10MinData={strikeComparisonMoreData}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default BrokerNiftyHistory;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { useAuth } from "../../AuthContext";

const SubscriptionRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const navigate = useNavigate();
  const { axiosInstance } = useAuth();

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await axiosInstance.post(`/get-subscription-details`, {});
        setHasSubscription(response.data.active); // Assume API returns { active: true/false }
      } catch (error) {
        console.error("Error checking subscription:", error);
        setHasSubscription(false); // Default to no subscription on error
      } finally {
        setLoading(false);
      }
    };

    checkSubscription();
  }, [axiosInstance]);

  if (loading) {
    return <Typography sx={{ textAlign: "center", mt: 4 }}>Loading...</Typography>;
  }

  if (!hasSubscription) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack on small screens, horizontal on larger screens
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
          px: 3,
        }}
      >
        {/* Illustration Image */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: { xs: 3, md: 0 }, // Add margin bottom for small screens
            pr: { md: 4 }, // Add padding right for spacing
          }}
        >
          <img
            src="/subscribe.svg"
            alt="No Subscription"
            style={{ maxWidth: "100%", maxHeight: "600px", objectFit: "contain" }}
          />
        </Box>

        {/* Text Content */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h5" gutterBottom>
            You are missing out on critical insights to succeed in Markets.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Please subscribe now to access our premium charts.
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "#1976d2", color: "#fff" }}
            onClick={() => navigate("/payment")}
          >
            Go to Payment
          </Button>
        </Box>
      </Box>
    );
  }

  return children;
};

export default SubscriptionRoute;
// Register.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const Register = () => {
  return (
    <Box sx={{ marginTop: '40px', padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        Registration Process
      </Typography>
      <Box
        sx={{
          width: '150px',
          height: '4px',
          backgroundColor: '#1976d2',
          marginBottom: '20px',
        }}
      />

      {/* Content */}
      <Typography variant="body1" gutterBottom>
        To register on our platform, follow these steps:
      </Typography>
      <Typography variant="body1" gutterBottom>
        1. Click on the <strong>"Register"</strong> option available in the Header of the Landing or Help pages.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2. You will be redirected to the registration page at <a href="https://915trade.com/register" target="_blank" rel="noopener noreferrer">https://915trade.com/register</a>.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3. Fill in the required details in the registration form.
      </Typography>
      <Typography variant='body1' gutterBottom>
        4. Make sure you provide correct email and phone, as these will be verified.
      </Typography>
      <Typography variant='body1' gutterBottom>
        5. Solve the captcha, and click on <strong>"Register"</strong>.
      </Typography>
      <Typography variant="body1" gutterBottom>
        6. An OTP will be sent to your registered email address. Enter the OTP to verify your email address.
      </Typography>
      <Typography variant="body1" gutterBottom>
        7. Once the email is verified, your registration process is complete.
      </Typography>
      <Typography variant="body1" gutterBottom>
        8. Remember the phone and password provided. You will need to use these to login to the platform.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Important:</strong> If you do not verify the OTP and return to the platform (either by attempting to register again or trying to log in), you will be redirected to the <strong>Reset Password</strong> page.
      </Typography>

      {/* Image */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
          marginBottom: '20px',
          borderBottom: '1px solid #ccc', // Border below the image
        }}
      >
        <img
          src="/registration.png"
          alt="Registration Process"
          style={{
            maxWidth: '100%',
            maxHeight: '400px',
            objectFit: 'contain',
            backgroundColor: '#ffffff', // Ensure the image background is white
          }}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        
      </Typography>
    </Box>
  );
};

export default Register;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kite-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f8f9fa;
}

.kite-login-box {
    width: 400px;
    padding: 20px;
    text-align: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.kite-login-box h1 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

.kite-login-box p {
    margin-bottom: 20px;
    color: #555;
}

.kite-login-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.kite-login-button:hover {
    background-color: #0056b3;
}

.kite-login-loading {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/KiteLogin.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".kite-login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 80vh;\n    background-color: #f8f9fa;\n}\n\n.kite-login-box {\n    width: 400px;\n    padding: 20px;\n    text-align: center;\n    background: white;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.kite-login-box h1 {\n    margin-bottom: 20px;\n    font-size: 1.5rem;\n    color: #333;\n}\n\n.kite-login-box p {\n    margin-bottom: 20px;\n    color: #555;\n}\n\n.kite-login-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 4px;\n    font-size: 1rem;\n    cursor: pointer;\n}\n\n.kite-login-button:hover {\n    background-color: #0056b3;\n}\n\n.kite-login-loading {\n    text-align: center;\n    font-size: 1.2rem;\n    color: #666;\n    padding-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

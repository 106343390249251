import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        fontFamily: 'Poppins, sans-serif',
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
          transform: 'translateY(-5%)',
        }}
      >
        {/* Background Image with Low Opacity */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/background.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.3,
            zIndex: -1,
          }}
        />

        {/* Top Left Icon */}
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            left: '5%',
            zIndex: 0,
            opacity: 0.1,
          }}
        >
          <TrendingUpIcon sx={{ fontSize: '16rem', color: '#1e88e5' }} />
        </Box>

        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            color: '#1e88e5',
            marginBottom: '1rem',
            zIndex: 1,
          }}
        >
          Trade Nifty and Options with Confidence
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#455a64',
            maxWidth: '800px',
            lineHeight: '1.5',
            marginBottom: '2rem',
            zIndex: 1,
          }}
        >
          Trusted by traders for unmatched clarity and simplicity.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/register')}
          sx={{
            padding: '15px 30px',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            backgroundColor: '#ff6f00',
            borderRadius: '50px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            ':hover': {
              backgroundColor: '#e65100',
              boxShadow: '0 6px 14px rgba(0, 0, 0, 0.3)',
            },
            zIndex: 1,
          }}
        >
          Boost Your Trades Now!
        </Button>
      </Box>

      {/* Decorative Divider */}
      <Box
        sx={{
          width: '80%',
          height: '30px',
          background: 'linear-gradient(to right, #1e88e5, #ff6f00)',
        }}
      />

      {/* Section 2 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
          padding: '4rem 2rem',
          marginTop: '2rem',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Box
          sx={{
            flex: 1,
            maxWidth: '600px',
            textAlign: { xs: 'center', md: 'left' },
            paddingRight: { md: '6rem' },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#1e88e5',
              marginBottom: '1rem',
            }}
          >
            What Questions Do You Ask Before Taking a Trade?
          </Typography>
          <Box component="ul" sx={{ color: '#455a64', marginBottom: '1.5rem', lineHeight: '1.8' }}>
            <li>Will this momentum continue?</li>
            <li>Where will this momentum stop?</li>
            <li>How long shall I hold my position?</li>
            <li>How do you answer these questions today?</li>
          </Box>
          <Typography
            variant="body1"
            sx={{
              color: '#e65100',
              marginBottom: '1.5rem',
              fontWeight: 'bold',
              lineHeight: '1.8',
            }}
          >
            What if we told you there is a simple way to know this?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#455a64',
              lineHeight: '1.8',
            }}
          >
            <strong>915trade.com</strong> strike-wise real-time insights can bring clarity in terms of where resistance is, and whether momentum will continue or shift based on buyer vs seller interest at each strike. Never before have traders had this level of clarity.
          </Typography>
        </Box>

        {/* Embedded YouTube Video */}
        <Box
          sx={{
            flex: 1,
            maxWidth: '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/3JMoLUd6A7U"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>

      {/* Decorative Divider at the Bottom */}
      <Box
        sx={{
          width: '80%',
          height: '30px',
          background: 'linear-gradient(to right, #1e88e5, #ff6f00)',
          marginLeft: 'auto',
        }}
      />

      {/* Section 3 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
          padding: '4rem 2rem',
          marginTop: '2rem',
          backgroundColor: '#ffffff',
        }}
      >
        <Box
          sx={{
            flex: 1,
            maxWidth: '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/MQYqnKV9ez8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>

        <Box
          sx={{
            flex: 1,
            maxWidth: '600px',
            textAlign: { xs: 'center', md: 'left' },
            paddingLeft: { md: '6rem' },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#ff6f00',
              marginBottom: '1rem',
            }}
          >
            See the Invisible – Spot Momentum Shifts Before Prices Move!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#455a64',
              marginBottom: '1.5rem',
              lineHeight: '1.8',
            }}
          >
            What if you’re in the middle of a trade, and suddenly, there’s a surge of volume in the opposite direction? Would you be prepared to act?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#455a64',
              marginBottom: '1.5rem',
              lineHeight: '1.8',
            }}
          >
            <strong>915trade.com</strong> lets you detect spikes in buying or selling pressure even before the price moves, giving you the edge to act swiftly and confidently. Stop guessing – start trading with precision.
          </Typography>
          <Box component="ul" sx={{ color: '#455a64', lineHeight: '1.8' }}>
            <li>Detect sudden volume surges at critical levels.</li>
            <li>Act on early signs of pressure before price reacts.</li>
            <li>Gain unmatched timing in your trades.</li>
          </Box>
        </Box>
      </Box>

      {/* Decorative Divider */}
      <Box
        sx={{
          width: '80%',
          height: '30px',
          background: 'linear-gradient(to right, #1e88e5, #ff6f00)',
        }}
      />

      {/* Section 4 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
          padding: '4rem 2rem',
          marginTop: '2rem',
          backgroundColor: '#ffffff',
        }}
      >
        <Box
          sx={{
            flex: 1,
            maxWidth: '600px',
            textAlign: { xs: 'center', md: 'left' },
            paddingRight: { md: '6rem' },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#1e88e5',
              marginBottom: '1rem',
            }}
          >
            The Day Buyers or Sellers Take Over – Are You Ready to Profit?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#455a64',
              marginBottom: '1.5rem',
              lineHeight: '1.8',
            }}
          >
            Imagine a trading day when buyers or sellers dominate the market with unmistakable strength. Wouldn’t it be a game-changer if you could spot this early and align your trades with the winning side?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#455a64',
              marginBottom: '1.5rem',
              lineHeight: '1.8',
            }}
          >
            At <strong>915trade.com</strong>, our charts lay out days of clear dominance so you can avoid the guesswork and make confident decisions. By analyzing buyer-seller pressure trends, we help you adapt your strategy to maximize gains and minimize risks. Whether it's an explosive rally or a sharp decline, you'll know exactly which side holds the reins.
          </Typography>
          <Box component="ul" sx={{ color: '#455a64', lineHeight: '1.8' }}>
            <li>Identify days with clear buyer or seller pressure dominance.</li>
            <li>Understand how market forces shape trends.</li>
            <li>Optimize your strategy to stay ahead of the curve.</li>
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate('/register')}
            sx={{
              marginTop: '2rem',
              padding: '10px 20px',
              fontSize: '1rem',
              fontWeight: 'bold',
              backgroundColor: '#ff6f00',
              borderRadius: '50px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
              ':hover': {
                backgroundColor: '#e65100',
                boxShadow: '0 6px 14px rgba(0, 0, 0, 0.3)',
              },
            }}
          >
            Register Now
          </Button>
        </Box>

        {/* Two Images on the Right */}
        <Box
          sx={{
            flex: 1,
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
          }}
        >
          <img
            src="/landing_volume.png"
            alt="Volume Insights"
            style={{ width: '100%', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)' }}
          />
          <img
            src="/landing_price.png"
            alt="Candle Movement Insights"
            style={{ width: '100%', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
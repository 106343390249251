import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  Grid,
} from "@mui/material";
import { baseAPIURL } from "../../Config";
import "../../styles/Login.css";

const PasswordReset = () => {
  const [step, setStep] = useState(1); // Steps: 1 = Enter Phone, 2 = Enter OTP, 3 = Reset Password
  const [formData, setFormData] = useState({
    phone: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateStep = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/;
    const otpRegex = /^[0-9]{6}$/;

    if (step === 1) {
      // Validate phone number
      if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) {
        newErrors.phone = "Phone number must be exactly 10 digits.";
      }
    } else if (step === 2) {
      // Validate OTP
      if (!formData.otp.trim() || !otpRegex.test(formData.otp)) {
        newErrors.otp = "OTP must be exactly 6 digits.";
      }
    } else if (step === 3) {
      // Validate passwords
      if (!formData.password.trim() || formData.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters.";
      }
      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError("");

    if (!validateStep()) return;

    try {
      if (step === 1) {
        // Step 1: Send OTP
        const response = await fetch(`${baseAPIURL}/resend-otp`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ phone: formData.phone }),
        });
        const result = await response.json();
        if (result.status === "success") {
          setStep(2); // Move to OTP verification
        } else {
          setApiError(result.reason || "Unable to send OTP.");
        }
      } else if (step === 2) {
        // Step 2: Verify OTP
        const response = await fetch(`${baseAPIURL}/verify-otp`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone: formData.phone,
            otp: formData.otp,
          }),
        });
        const result = await response.json();
        if (result.status === "success") {
          setStep(3); // Move to Reset Password
        } else {
          setApiError(result.reason || "Invalid OTP.");
        }
      } else if (step === 3) {
        // Step 3: Reset Password
        const response = await fetch(`${baseAPIURL}/reset-password`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone: formData.phone,
            password: formData.password,
            confirmPassword: formData.confirmPassword
          }),
        });
        const result = await response.json();
        if (result.status === "SUCCESS") {
            setSuccessMessage("Password reset successfully! You can now log in with your new password.");
            setStep(4);
            window.location.href = "/login";
        } else {
          setApiError(result.reason || "Unable to reset password.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setApiError("Something went wrong. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "80vh", px: 3 }}
      >
        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/login.svg"
            alt="Infographic"
            style={{ width: "100%", borderRadius: "10px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#1976d2",
              fontWeight: "bold",
              fontFamily: "'Roboto', sans-serif",
              textAlign: "center",
            }}
          >
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: "100%",
              maxWidth: 400,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            {step === 1 && (
              <>
                <TextField
                  label="Phone Number"
                  name="phone"
                  variant="outlined"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#1976d2", color: "#fff" }}
                >
                  Send OTP
                </Button>
              </>
            )}

            {step === 2 && (
              <>
                <Typography variant="body2" sx={{ color: "green", textAlign: "center", mb: 2 }}>
                    OTP is sent to your registered email ID.
                </Typography>
                <TextField
                  label="OTP"
                  name="otp"
                  variant="outlined"
                  value={formData.otp}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={!!errors.otp}
                  helperText={errors.otp}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#1976d2", color: "#fff" }}
                >
                  Verify OTP
                </Button>
              </>
            )}

            {step === 3 && (
              <>
                <TextField
                  label="New Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={!!errors.password}
                  helperText={errors.password}
                />
                <TextField
                  label="Repeat Password"
                  name="confirmPassword"
                  type="password"
                  variant="outlined"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#1976d2", color: "#fff" }}
                >
                  Reset Password
                </Button>
              </>
            )}

            {step === 4 && (
                <Typography
                    variant="h6"
                    sx={{
                    color: "green",
                    textAlign: "center",
                    mt: 2,
                    fontWeight: "bold",
                    }}
                >
                    {successMessage}
                </Typography>
            )}

            {apiError && (
              <Typography
                variant="body2"
                sx={{ color: "red", mt: 1, textAlign: "center" }}
              >
                {apiError}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordReset;
// src/config.js

const config = {
    development: {
      baseAPIURL: "http://127.0.0.1:5000/api/data",
      razorPayURL: "https://rzp.io/rzp/BjBwn7c1",
      kiteLoginURL: "https://kite.zerodha.com/connect/login?v=3&api_key=j57eopk7fwab5y4w",
    },
    production: {
        baseAPIURL: "https://api.915trade.com/api/data",
        razorPayURL: "https://rzp.io/rzp/BjBwn7c1",
        kiteLoginURL: "https://kite.zerodha.com/connect/login?v=3&api_key=j57eopk7fwab5y4w",
    },
  };
  
  const environment = "production";
  
  export const baseAPIURL = config[environment].baseAPIURL;
  export const razorPayURL = config[environment].razorPayURL;
  export const kiteLoginURL = config[environment].kiteLoginURL;

import React, { useState } from "react";
import {
    Box,
    Grid,
    TextField,
    Button,
    Typography,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputLabel,
    FormControl,
    Modal,
} from "@mui/material";
import CandleChart from "../charts/CandleChart";
import { useAuth } from "../../AuthContext";

const SimulationTradeMgmt = ({ tradeDate, tradeTime, simulationID, openOrders, setOpenOrders, completedTrades, setCompletedTrades }) => {
    const { axiosInstance } = useAuth();
    const [strike, setStrike] = useState("");
    const [callType, setCallType] = useState("Call");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [candleData, setCandleData] = useState(null);
    const [strikeError, setStrikeError] = useState(false);

    const fetchCandleData = async () => {
        if (!strike || !callType) {
            setStrikeError(true);
            return;
        }
        setStrikeError(false);
    
        try {
            const response = await axiosInstance.post("/get-history-strike-candle-data", {
                trade_date: tradeDate,
                time: tradeTime,
                strike: strike,
                call_type: callType,
            });
            setCandleData(response.data);
            setIsModalOpen(true); // Open the modal after fetching data
        } catch (error) {
            console.error("Error fetching candle data:", error);
        }
    };

    const handleBuy = async () => {
        if (!strike || !callType) {
            setStrikeError(true);
            return;
        }
        setStrikeError(false);

        try {
            const response = await axiosInstance.post("/simulation-options-buy", {
                asset: "NIFTY",
                trade_date: tradeDate,
                time: tradeTime,
                strike: strike,
                call_type: callType,
                simulationID: simulationID,
            });

            const entryPrice = response.data['entryPrice'];
            const tradeID = response.data['tradeID'];
            const newOrder = {
                id: simulationID,
                tradeID: tradeID,
                strike,
                callType,
                entryDate: tradeDate,
                entryTime: tradeTime + ":00",
                entryPrice: entryPrice,
                lots: 1,
                investedAmount: entryPrice * 75,
                exitedTime: "",
                exitPrice: "",
                profit: "",
                pandl: ""
            };
            setOpenOrders([...openOrders, newOrder]);
        } catch (error) {
            console.error("Error fetching candle data:", error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleBuyMore = async(tradeID) => {
        const orderToRepeat = openOrders.find((order) => order.tradeID === tradeID);
        try {
            const response = await axiosInstance.post("/simulation-options-buy", {
                asset: "NIFTY",
                trade_date: tradeDate,
                time: tradeTime,
                strike: orderToRepeat.strike,
                call_type: orderToRepeat.callType,
                simulationID: simulationID,
            });

            const entryPrice = response.data['entryPrice'];
            const newTradeID = response.data['tradeID'];
            const newOrder = {
                id: simulationID,
                tradeID: newTradeID,
                strike: orderToRepeat.strike,
                callType: orderToRepeat.callType,
                entryDate: tradeDate,
                entryTime: tradeTime + ":00",
                entryPrice: entryPrice,
                lots: 1,
                investedAmount: entryPrice * 75,
                exitedTime: "",
                exitPrice: "",
                profit: "",
                pandl: ""
            };
            setOpenOrders([...openOrders, newOrder]);
        } catch (error) {
            console.error("Error fetching candle data:", error);
        }
    };

    const handleExitNow = async(tradeID) => {
        const orderToExit = openOrders.find((order) => order.tradeID === tradeID);
        const remainingOrders = openOrders.filter((order) => order.tradeID !== tradeID);

        try {
            const response = await axiosInstance.post("/simulation-options-exit", {
                tradeID: tradeID,
                trade_date: tradeDate,
                time: tradeTime,
                strike: orderToExit['strike'],
                callType: orderToExit['callType']
            });

            const exitPrice = response.data['exitPrice'];
            
            const updatedOrder = {
                ...orderToExit,
                exitPrice: exitPrice,
                exitTime: tradeTime + ":00",
                profit: exitPrice - orderToExit.entryPrice,
                pandl: (exitPrice - orderToExit.entryPrice) * 75
            };

            setOpenOrders(remainingOrders);
            setCompletedTrades([...completedTrades, updatedOrder]);
        } catch (error) {
            console.error("Error fetching candle data:", error);
        }
    };

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "#f5f5f5",
                height: "100%",
                overflowY: "auto",
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    color: "#0B99FF",
                    mb: 3,
                    fontWeight: "bold",
                }}
            >
                Trade Simulator
            </Typography>

            {/* Order Placement Section */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={3}>
                    <TextField
                        label="Strike"
                        value={strike}
                        onChange={(e) => setStrike(e.target.value)}
                        fullWidth
                        variant="outlined"
                        error={strikeError} // Apply red border if there's an error
                        helperText={strikeError ? "Please enter a valid strike price" : ""} // Optional helper text
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>Call Type</InputLabel>
                        <Select
                            value={callType}
                            onChange={(e) => setCallType(e.target.value)}
                        >
                            <MenuItem value="Call">Call</MenuItem>
                            <MenuItem value="Put">Put</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="outlined"
                        onClick={fetchCandleData}
                        fullWidth
                        sx={{ color: "#0B99FF", borderColor: "#0B99FF" }}
                    >
                        Show Chart
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        onClick={handleBuy}
                        fullWidth
                        sx={{ backgroundColor: "#0B99FF", color: "white" }}
                    >
                        Buy
                    </Button>
                </Grid>
            </Grid>

            {/* Open Orders Section */}
            <Typography
                variant="h6"
                sx={{
                    color: "#0B99FF",
                    mb: 2,
                    fontWeight: "bold",
                }}
            >
                Open Orders
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Strike</TableCell>
                        <TableCell>Call Type</TableCell>
                        <TableCell>Entry Date</TableCell>
                        <TableCell>Entry Time</TableCell>
                        <TableCell>Entry Price</TableCell>
                        <TableCell>Lots</TableCell>
                        <TableCell>Invested Amount</TableCell>
                        <TableCell>Profit</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {openOrders.map((order) => (
                        <TableRow key={order.tradeID}>
                            <TableCell>{order.strike}</TableCell>
                            <TableCell>{order.callType}</TableCell>
                            <TableCell>{order.entryDate}</TableCell>
                            <TableCell>{order.entryTime}</TableCell>
                            <TableCell>{order.entryPrice.toFixed(2)}</TableCell>
                            <TableCell>{order.lots}</TableCell>
                            <TableCell>{order.investedAmount}</TableCell>
                            <TableCell>{order.profit !== "" && order.profit !== null && order.profit !== undefined ? order.profit.toFixed(2) : "--"}</TableCell>
                            <TableCell>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => handleBuyMore(order.tradeID)}
                                    sx={{ mr: 1, backgroundColor: "#0B99FF", color: "white" }}
                                >
                                    Buy More
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleExitNow(order.tradeID)}
                                >
                                    Exit Now
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Completed Trades Section */}
            <Typography
                variant="h6"
                sx={{
                    color: "#0B99FF",
                    mt: 4,
                    mb: 2,
                    fontWeight: "bold",
                }}
            >
                Completed Trades
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Strike</TableCell>
                        <TableCell>Call Type</TableCell>
                        <TableCell>Entry Time</TableCell>
                        <TableCell>Entry Price</TableCell>
                        <TableCell>Lots</TableCell>
                        <TableCell>Exit Time</TableCell>
                        <TableCell>Exit Price</TableCell>
                        <TableCell>Profit</TableCell>
                        <TableCell>Invested Amount</TableCell>
                        <TableCell>P&L</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {completedTrades.map((trade) => (
                        <TableRow key={trade.id}>
                            <TableCell>{trade.strike}</TableCell>
                            <TableCell>{trade.callType}</TableCell>
                            <TableCell>{trade.entryTime}</TableCell>
                            <TableCell>{trade.entryPrice.toFixed(2)}</TableCell>
                            <TableCell>{trade.lots}</TableCell>
                            <TableCell>{trade.exitTime}</TableCell>
                            <TableCell>{trade.exitPrice.toFixed(2)}</TableCell>
                            <TableCell>{trade.profit.toFixed(2)}</TableCell>
                            <TableCell>{trade.investedAmount.toFixed(2)}</TableCell>
                            <TableCell>{trade.pandl.toFixed(2)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Modal for CandleChart */}
            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        bgcolor: "background.paper",
                        border: "2px solid #0B99FF",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#0B99FF",
                            mb: 2,
                            fontWeight: "bold",
                        }}
                    >
                        Candle Chart
                    </Typography>
                    {candleData ? (
                        <CandleChart data={candleData} />
                    ) : (
                        <Typography>Loading chart data...</Typography>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default SimulationTradeMgmt;
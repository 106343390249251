// NiftyLiveStrike.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const NiftyLiveStrike = () => {
  return (
    <Box sx={{ marginTop: '40px', padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        Strike wise view of Nifty - Live
      </Typography>
      <Box
        sx={{
          width: '150px',
          height: '4px',
          backgroundColor: '#1976d2',
          marginBottom: '20px',
        }}
      />

      {/* Content */}
      <Typography variant="body1" gutterBottom>
        To view this page you can click on Live Charts in the Header menu and then click on Nifty Strike View.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1. This page shows you Call vs Put sellers data, 1 strike at a time. It also shows you 1 minute candles for that strike for both Call and Put.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2. To see strike data you will first have to select strikes that you want to track. For example, please see the image below.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/nifty_live_strike1.png"
          alt="Nifty Live Strike View"
          style={{
            width: '100%',
            maxWidth: '1000px',
            height: '400px',
            borderRadius: '8px',
          }}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        3. From the Drop Down you can check all the strikes you would like to track and they will get added as buttons on the right side of the drop down.
      </Typography>
      <Typography variant="body1" gutterBottom>
        4. You can select as many strikes as you like. Important is to select the strikes you are interested-in, at any given point in time.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5. You can then click on any 1 button on the right side to see data of that strike. It will display the data as shown in following image.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/nifty_live_strike2.png"
          alt="Nifty Live Strike View"
          style={{
            width: '100%',
            maxWidth: '800px',
            height: '400px',
            borderRadius: '8px',
          }}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        6. As shown in image above, user clicked on strike button 23000. The lines show data of 23000.
      </Typography>
      <Typography variant="body1" gutterBottom>
        7. The two lines represent Call and Put sell volumes. Red line is for Call volume and Green is for Put line
      </Typography>
      <Typography variant="body1" gutterBottom>
        8. In general the one at the top is stronger and that asset's price ideally should drop. However, this is not always the case. It is important to understand the intricacies before taking any trade decisions.
      </Typography>
      <Typography variant="body1" gutterBottom>
        9. Rising line indicates increased supply, indicating that asset's price going down.
      </Typography>
      <Typography variant="body1" gutterBottom>
        10. Either stagnant volumes (Especially when the asset line is below) or reducing volumes indicate the asset's price increasing.
      </Typography>
      <Typography variant="body1" gutterBottom>
        11. When scrolled down the page also shows Call and Put candles till that time.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/nifty_live_strike3.png"
          alt="Nifty Live Strike View"
          style={{
            width: '100%',
            maxWidth: '1000px',
            height: '600px',
            borderRadius: '8px',
          }}
        />
      </Box>
    </Box>
  );
};

export default NiftyLiveStrike;
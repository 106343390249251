import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const BankNiftyLive = () => {
  const [currentSection, setCurrentSection] = useState(0);

  const sections = [
    {
      title: 'Banknifty - Buy & Sell Volume Chart',
      content: (
        <>
        <Typography variant="body1" gutterBottom>
            This is the first chart displayed on the Banknifty Live Insights Dashboard.
        </Typography>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            }}
        >
            <img
            src="/banknifty_live_chart1.png"
            alt="Banknifty Buy vs Sell Volumes"
            style={{
                width: '100%',
                maxWidth: '800px',
                height: '500px',
                borderRadius: '8px',
            }}
            />
        </Box>
        <Typography variant="body1" gutterBottom>
            1. The data represents Buy vs Sell contracts at Futures level. It helps to understand the Buy vs Sell pressure at futures level.
        </Typography>
        <Typography variant="body1" gutterBottom>
            2. The red line represents Sell contracts/volumes, and the green line represents Buy contracts.
        </Typography>
        <Typography variant="body1" gutterBottom>
            3. The idea is to be able to understand whether Buyers are storng or Sellers are strong.
        </Typography>
        <Typography variant="body1" gutterBottom>
            4. We have observed, for Banknifty price to continue to slide, the sellers will have to continue to sell more contracts.
        </Typography>
        <Typography variant="body1" gutterBottom>
            5. Similarly, for price to rise, the buyers will have to continue to buy more contracts.
        </Typography>
        <Typography variant="body1" gutterBottom>
            6. If the line is constant, it usually means not enough new contracts are being either Bought or Sold.
        </Typography>
        </>
      ),
    },
    {
      title: 'Banknifty - Buy -(minus) Sell Volume Chart',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the second chart displayed on the Banknifty Live Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/banknifty_live_chart2.png"
                alt="Banknifty Buy vs Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	The data represents the difference between Buy and Sell contracts at the Futures level (Buy - Sell).
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	It reflects the minute-by-minute difference between the Green and Red lines shown in the first chart.
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.	The purpose of this chart is to identify subtle patterns in Buyer and Seller behavior, particularly during periods of low liquidity.
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.	The line below 0 indicates stronger selling activity, while the line above 0 indicates stronger buying activity.
            </Typography>
            <Typography variant="body1" gutterBottom>
                5.	A descending line signifies sustained selling pressure, whereas an ascending line indicates sustained buying pressure.
            </Typography>
        </>
      ),
    },
    {
      title: 'Banknifty - Spot Candles Chart',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the third chart displayed on the Banknifty Live Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/banknifty_live_chart3.png"
                alt="Index Buy vs Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	These are Banknifty Spot 1 minute candles.
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	Since ATM, OTM and ITM is decided based on Spot price, we show these candles, to make it easy to corelate with other data.
            </Typography>
        </>
      ),
    },
    {
      title: 'Banknifty - Strike-wise Buy & Sell Volume Chart',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the fourth chart displayed on the Banknifty Live Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/banknifty_live_chart4.png"
                alt="Banknifty Strike wise Sell Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	You can see strike wise Buy vs Sell strength. Buy is in Green and Sell is in Red.
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	Buy bars are nothing but Put Sell volumes. Since higher put sell volumes push nifty UP we show it in green.
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.	Sell bars are nothing but Call Sell volumes. Since higher call sell volumes push nifty DOWN we show it in green.
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.	At any given point in time we show ATM Strike, 5 strikes below and 5 strikes above.
            </Typography>
            <Typography variant="body1" gutterBottom>
                5.	As the Banknifty spot price moves, the ATM strike in this chart changes. As the ATM strike changes, the +5 strikes and -5 strikes also change.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.	The top right corner of this chart we show consolidated numbers of Buy vs Sell and the total difference.
            </Typography>
            <Typography variant="body1" gutterBottom>
                7.	For example, in the above image, you see Buy at 439K, Sell at 600KM and Diff as -161K.
            </Typography>
            <Typography variant="body1" gutterBottom>
                8.	If Put sellers are high, the diff will be positive, if Call sellers are high, the diff will be negative.
            </Typography>
            <Typography variant="body1" gutterBottom>
                9.	The volumes on bars give you an idea on the liquidity as well. We request the user to view historical data to get a sense of volumes and how they reveal High liquidity and Low liquidity days.
            </Typography>
            <Typography variant="body1" gutterBottom>
                10.	In general, strikes below the ATM Put sellers (Buyers) will be strong, and strikes above the ATM, Call sellers (Sellers) will be strong.
            </Typography>
        </>
      ),
    },
    {
      title: 'Banknifty - Strike wise Buyers Insights',
      content: (
        <>
            <Typography variant="body1" gutterBottom>
                This is the fifth chart displayed on the Banknifty Live Insights Dashboard.
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                }}
            >
                <img
                src="/banknifty_live_chart5.png"
                alt="Banknifty Buyers Volumes"
                style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: '500px',
                    borderRadius: '8px',
                }}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                1.	These are Call and Put Buy volumes at each strike.
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.	The ATM strike is decided based on Nifty Spot price.
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.	At any given point in time we show 5 strikes below and 5 strikes above ATM.
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.	It is observed that on expiry day these volumes are significantly higher than on other days.
            </Typography>
        </>
      ),
    },
  ];

  const handleNext = () => {
    setCurrentSection((prev) => {
      const nextSection = (prev + 1) % sections.length;
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page
      return nextSection;
    });
  };
  
  const handlePrevious = () => {
    setCurrentSection((prev) => {
      const previousSection = (prev - 1 + sections.length) % sections.length;
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page
      return previousSection;
    });
  };

  return (
    <Box sx={{ marginTop: '40px', padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        {sections[currentSection].title}
      </Typography>
      <Box
        sx={{
          width: '150px',
          height: '4px',
          backgroundColor: '#1976d2',
          marginBottom: '20px',
        }}
      />

      {/* Content */}
      {sections[currentSection].content}

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button
          variant="outlined"
          onClick={handlePrevious}
          disabled={currentSection === 0}
        >
          Previous Section
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={currentSection === sections.length - 1}
        >
          Next Section
        </Button>
      </Box>
    </Box>
  );
};

export default BankNiftyLive;
import React from "react";
import { Box, Typography, Divider } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ fontFamily: "Roboto, sans-serif", px: 3, py: 5 }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3, color: "#1976d2" }}>
        Privacy Policy
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        Welcome to 915trade.com. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our services. By accessing our platform, you agree to the terms outlined in this Privacy Policy.
      </Typography>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        We collect the following types of information:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            <strong>Personal Information:</strong> When you register, subscribe, or make payments, we may collect your name, email address, phone number, and payment details.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Usage Data:</strong> Information about how you use our platform, including analytics, device information, IP address, browser type, and pages visited.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Cookies:</strong> We use cookies to enhance your browsing experience and track website activity.
          </Typography>
        </li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We use your information to:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Provide you with access to our services and analytics tools.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Process your subscriptions and payments securely.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Improve our platform through user feedback and analytics.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Communicate important updates, offers, or service changes.
          </Typography>
        </li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        3. How We Protect Your Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We implement industry-standard security measures to protect your personal information, including encryption, secure servers, and data access controls. However, no system is 100% secure, and we cannot guarantee absolute security.
      </Typography>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        4. Sharing Your Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We do not sell or share your personal information with third parties, except as necessary to:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Process payments securely through payment gateways.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Comply with legal obligations or law enforcement requests.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Provide analytics services or technical support through trusted vendors.
          </Typography>
        </li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        5. Your Data Rights
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        You have the right to:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Access, update, or delete your personal information.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Opt-out of marketing communications at any time.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Request a copy of your personal data we store.
          </Typography>
        </li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        6. Use of Cookies
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Cookies are small data files stored on your device. We use cookies to:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Improve the functionality and performance of our platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Track user activity and preferences for better user experience.
          </Typography>
        </li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        7. Third-Party Links
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Our platform may contain links to third-party websites. We are not responsible for their privacy practices or content, and we encourage you to review their privacy policies.
      </Typography>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        8. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of the platform constitutes acceptance of those changes.
      </Typography>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        9. Contact Us
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@915trade.com">support@915trade.com</a>.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
// ResetPassword.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const ResetPassword = () => {
  return (
    <Box sx={{ marginTop: '40px', padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        Forgot or Reset Password Process
      </Typography>
      <Box
        sx={{
          width: '150px',
          height: '4px',
          backgroundColor: '#1976d2',
          marginBottom: '20px',
        }}
      />

      {/* Content */}
      <Typography variant="body1" gutterBottom>
        To reset your password, follow these steps:
      </Typography>
      <Typography variant="body1" gutterBottom>
        1. Click on the <strong>"Forgot Password"</strong> option available on the login page.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2. You will be redirected to the password reset page at <a href="https://915trade.com/password-reset" target="_blank" rel="noopener noreferrer">https://915trade.com/password-reset</a>.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3. Enter the phone number you used for registration.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/reset_password.png"
          alt="Reset Password process"
          style={{
            width: '100%',
            maxWidth: '500px',
            height: 'auto',
            borderRadius: '8px',
          }}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        4. Click on <strong>"Send OTP"</strong>. The system will send a One-Time Password (OTP) to your registered email address.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5. Enter the OTP received in your email.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/reset_password_otp.png"
          alt="OTP Verification"
          style={{
            width: '100%',
            maxWidth: '500px',
            height: 'auto',
            borderRadius: '8px',
          }}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        6. Once the OTP is verified, the system will prompt you to enter a new password.
      </Typography>
      <Typography variant="body1" gutterBottom>
        7. Enter your new password and confirm it. Once done, the password will be reset successfully.
      </Typography>
      <Typography variant="body1" gutterBottom>
        8. The system will mark your account as verified after a successful password reset.
      </Typography>
      <Typography variant="body1" gutterBottom>
        9. You can now navigate back to the Login page by clicking on the <strong>"Login"</strong> link in the Header.
      </Typography>
      <Typography variant="body1" gutterBottom>
        10. Use your phone number and the new password to log in to the portal.
      </Typography>
    </Box>
  );
};

export default ResetPassword;
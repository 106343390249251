import React, { useState, useEffect } from "react";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import StrikeBars from "../charts/StrikeBars";
import { useAuth } from "../../AuthContext";
import { Box, Dialog, DialogContent, DialogTitle, CircularProgress, Backdrop, Typography } from "@mui/material";
import StrikeComparisonChart from "../charts/StrikeComparisonChart";

const NiftyIndexLiveMobile = () => {
  const { auth, axiosInstance } = useAuth();
  const [lineData, setLineData] = useState(null);
  const [secondLineData, setSecondLineData] = useState(null);
  const [strikesData, setStrikesData] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const [strikeComparisonData, setStrikeComparisonData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sellData, setSellData] = useState(null);
  const [strikeCandleData, setStrikeCandleData] = useState(null);
  const [clickedStrike, setClickedStrike] = useState(null);
  const [strikeDataLoading, setStrikeDataLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lineResponse = await axiosInstance.post(`/get-nifty-index-lines`, { index: "NIFTY" });
        const candleResponse = await axiosInstance.post(`/get-nifty-index-candles`, { index: "NIFTY" });
        const strikesResponse = await axiosInstance.post(`/get-nifty-nearest-strike-buysell`, { index: "NIFTY" });
        const strikeComparisonResponse = await axiosInstance.post(`/get-nifty-nearest-strike-buypressure`, { index: "NIFTY" });

        setLineData(lineResponse.data);
        setCandleData(candleResponse.data);
        setStrikesData(strikesResponse.data);
        setStrikeComparisonData(strikeComparisonResponse.data);
        setSecondLineData({
          ...lineResponse.data,
          CE: lineResponse.data.PE.map((peItem, index) => ({
            candleTime: peItem.candleTime,
            sell_volume: peItem.sell_volume - (lineResponse.data.CE[index]?.sell_volume || 0),
          })),
          PE: [],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [axiosInstance]);

  const handleBarClick = async (strike) => {
    try {
      setStrikeDataLoading(true);
      const response = await axiosInstance.post(`/get-sell-data`, { strike });
      const response1 = await axiosInstance.post(`/get-candle-data`, { strike });
      setSellData(response.data);
      setStrikeCandleData(response1.data);
      setClickedStrike(strike);
      setStrikeDataLoading(false);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching sell data:", error);
    }
  };

  return (
    <>
      {/* Loader */}
      {strikeDataLoading && (
        <Backdrop
          open={true}
          sx={{
            zIndex: 2000,
            color: "#fff",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="h6" sx={{ marginBottom: 1, fontWeight: "bold", textAlign: "left" }}>
                Index Buyers vs Sellers
            </Typography>
            {lineData && <LineChart data={lineData} legends={["Sell", "Buy"]} />}
        </Box>
        {/* Second Line Chart */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="h6" sx={{ marginBottom: 1, fontWeight: "bold", textAlign: "left" }}>
               Index: Buy - Sell Volume
            </Typography>
            {secondLineData && <LineChart data={secondLineData} legends={["Diff Volume"]} />}
        </Box>

        {/* Candle Chart */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="h6" sx={{ marginBottom: 1, fontWeight: "bold", textAlign: "left" }}>
                Nifty50 Spot Candles
            </Typography>
            {candleData && <CandleChart data={candleData} />}
        </Box>
        {strikesData && (
            <Box
                sx={{
                height: "500px", // Adjust the height as per your preference
                width: "100%", // Ensure it takes the full width
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                    marginBottom: 1, // Add some spacing below the title
                    fontWeight: "bold", // Make the title bold
                    textAlign: "left", // Align text to the left
                    }}
                >
                    Strike wise Sellers
                </Typography>
                <StrikeBars
                data={strikesData}
                isHistorical={false}
                onBarClick={(bar) => {
                    setStrikeDataLoading(true); // Start loader
                    handleBarClick(bar); // Handle bar click logic
                }}
                />
            </Box>
        )}
        {strikeComparisonData && (
            <Box
                sx={{
                height: "500px", // Adjust the height as needed
                width: "100%", // Ensure it takes the full width
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                    marginBottom: 1, // Add some spacing below the title
                    fontWeight: "bold", // Make the title bold
                    textAlign: "left", // Align text to the left
                    }}
                >
                    Strike wise Buyers
                </Typography>
                <StrikeComparisonChart
                data={strikeComparisonData}
                isHistorical={false}
                />
            </Box>
        )}
      </Box>

      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setStrikeDataLoading(false); // Stop loader after modal closes
        }}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 1500 }}
      >
        <DialogTitle>Strike Data - Strike:{clickedStrike}</DialogTitle>
        <DialogContent>
          {sellData && <LineChart data={sellData} legends={["Call", "Put"]} />}
          {strikeCandleData?.CE && <CandleChart data={strikeCandleData.CE} title="Call Candles" />}
          {strikeCandleData?.PE && <CandleChart data={strikeCandleData.PE} title="Put Candles" />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NiftyIndexLiveMobile;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5; /* Light background for better contrast */
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  .login-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
  }
  
  .login-header img {
    height: 40px;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 0 20px;
  }
  
  .login-infographic {
    width: 100%;
    border-radius: 10px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    gap: 20px;
    margin-top: 20px;
  }
  
  .login-button {
    background-color: #1976d2;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    text-align: center;
  }
  
  .login-button:hover {
    background-color: #1565c0;
  }
  
  .login-error {
    color: red;
    margin-top: 10px;
    text-align: center;
    font-size: 0.9rem;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Login.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,SAAS;IACT,UAAU;IACV,yBAAyB,EAAE,yCAAyC;EACtE;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;EACnB","sourcesContent":["body {\n    font-family: Roboto, sans-serif;\n    margin: 0;\n    padding: 0;\n    background-color: #f5f5f5; /* Light background for better contrast */\n  }\n  \n  img {\n    max-width: 100%;\n    height: auto;\n  }\n  \n  .login-header {\n    display: flex;\n    justify-content: flex-end;\n    padding: 10px 20px;\n  }\n  \n  .login-header img {\n    height: 40px;\n  }\n  \n  .login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 80vh;\n    padding: 0 20px;\n  }\n  \n  .login-infographic {\n    width: 100%;\n    border-radius: 10px;\n  }\n  \n  .login-form {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 400px;\n    gap: 20px;\n    margin-top: 20px;\n  }\n  \n  .login-button {\n    background-color: #1976d2;\n    color: #fff;\n    border: none;\n    padding: 10px;\n    cursor: pointer;\n    text-align: center;\n  }\n  \n  .login-button:hover {\n    background-color: #1565c0;\n  }\n  \n  .login-error {\n    color: red;\n    margin-top: 10px;\n    text-align: center;\n    font-size: 0.9rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { useAuth } from "../../AuthContext";
import { kiteLoginURL } from "../../Config";
import '../../styles/KiteLogin.css';
import { useSearchParams, useNavigate } from 'react-router-dom';

const KiteLogin = () => {
    const { axiosInstance } = useAuth();
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // Hook to navigate programmatically

    useEffect(() => {
        const verifyOrGenerateToken = async () => {
            const requestToken = searchParams.get('request_token');

            if (requestToken) {
                // If request_token is present, call /generate-kite-token API
                try {
                    const response = await axiosInstance.post('/generate-kite-token', {
                        request_token: requestToken,
                    });

                    if (response.data.status === 'SUCCESS') {
                        setStatus('SUCCESS');
                        navigate(window.location.pathname, { replace: true });
                    } else {
                        setStatus('FAILURE');
                    }
                } catch (error) {
                    setStatus('FAILURE');
                }
            } else {
                // If no request_token, verify existing kite login
                try {
                    const response = await axiosInstance.get('/verify-kite-login');
                    if (response.data.status === 'SUCCESS') {
                        setStatus('SUCCESS');
                    } else {
                        setStatus('FAILURE');
                    }
                } catch (error) {
                    setStatus('FAILURE');
                }
            }

            setLoading(false);
        };

        verifyOrGenerateToken();
    }, [axiosInstance, searchParams, navigate]);

    const handleLogin = () => {
        window.location.href = kiteLoginURL;
    };

    if (loading) {
        return <div className="kite-login-loading">Loading...</div>;
    }

    return (
        <div className="kite-login-container">
            <div className="kite-login-box">
                <h1>Kite Login</h1>
                {status === 'SUCCESS' ? (
                    <p>We have received kite access tokens, thank you.</p>
                ) : (
                    <>
                        <p>Click below to login to Kite.</p>
                        <button className="kite-login-button" onClick={handleLogin}>
                            Kite Login
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default KiteLogin;
// StrikeHistory.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const StrikeHistory = () => {
  return (
    <Box sx={{ marginTop: '40px', padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        Strike wise view of Index History
      </Typography>
      <Box
        sx={{
          width: '150px',
          height: '4px',
          backgroundColor: '#1976d2',
          marginBottom: '20px',
        }}
      />

      {/* Content */}
      <Typography variant="body1" gutterBottom>
        To view this page you can click on History Charts in the Header menu and then click on Strike View.
      </Typography>
      <Typography variant="body1" gutterBottom>
        To view data in this page you have to select Expiry, Trade Date and then the Strike to view, as seen in below image.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/strike_history1.png"
          alt="Index Strike wise View"
          style={{
            width: '100%',
            maxWidth: '800px',
            height: '300px',
            borderRadius: '8px',
          }}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        1. This page shows you Call vs Put sellers data, 1 strike at a time. It also shows you 1 minute candles for that strike for both Call and Put.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/strike_history2.png"
          alt="Index History Strike View"
          style={{
            width: '100%',
            maxWidth: '800px',
            height: '400px',
            borderRadius: '8px',
          }}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        6. As shown in image above, the lines show data for strike 23900 on 30th December 2024.
      </Typography>
      <Typography variant="body1" gutterBottom>
        7. The two lines represent Call and Put sell volumes. Red line is for Call volume and Green is for Put line
      </Typography>
      <Typography variant="body1" gutterBottom>
        8. In general the one at the top is stronger and that asset's price ideally should drop. However, this is not always the case. It is important to understand the intricacies before taking any trade decisions.
      </Typography>
      <Typography variant="body1" gutterBottom>
        9. Rising line indicates increased supply, indicating that asset's price going down.
      </Typography>
      <Typography variant="body1" gutterBottom>
        10. Either stagnant volumes (Especially when the asset line is below) or reducing volumes indicate the asset's price increasing.
      </Typography>
      <Typography variant="body1" gutterBottom>
        11. When scrolled down the page also shows Call and Put candles till that time.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/strike_history3.png"
          alt="Index History Strike View"
          style={{
            width: '100%',
            maxWidth: '1000px',
            height: '600px',
            borderRadius: '8px',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <img
          src="/strike_history4.png"
          alt="Index History Strike View"
          style={{
            width: '100%',
            maxWidth: '1000px',
            height: '600px',
            borderRadius: '8px',
          }}
        />
      </Box>
    </Box>
  );
};

export default StrikeHistory;
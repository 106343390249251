import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Autocomplete, TextField, Box, Modal, Button, Typography } from "@mui/material";

const BrokerBuyDiffChart = ({ data, onTimeChange, isHistorical, last10MinData }) => {
  const [selectedTime, setSelectedTime] = useState("15:29");
  const [chartData, setChartData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [hoverData, setHoverData] = useState(null); // Data for the line chart
  const [selectedStrike, setSelectedStrike] = useState(null); // Strike for line chart title

  const formatNumber = (value) => {
    if (Math.abs(value) >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    }
    return `${(value / 1_000).toFixed(1)}K`;
  };

  const generateTimeFrames = () => {
    const times = [];
    let hour = 9;
    let minute = 15;

    while (hour < 15 || (hour === 15 && minute < 30)) {
      times.push(`${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`);
      minute += 1;
      if (minute === 60) {
        hour += 1;
        minute = 0;
      }
    }
    return times;
  };

  const timeFrames = isHistorical ? generateTimeFrames() : [];

  useEffect(() => {
    if (data) {
      const atmStrike = {
        ...data.atmStrike,
        label: `ATM Strike: ${data.atmStrike.strike}`,
      };
  
      // Modify lowerStrikes to set Call Buy volumes to 0
      const lowerStrikes = data.lowerStrikes.map((strike) => ({
        ...strike,
        call_buy_volume: 0,
        call_sell_volume: 0, // Ensure balance in calculation
        label: `Strike: ${strike.strike}`,
      }));
  
      // Modify upperStrikes to set Put Buy volumes to 0
      const upperStrikes = data.upperStrikes.map((strike) => ({
        ...strike,
        put_buy_volume: 0,
        put_sell_volume: 0, // Ensure balance in calculation
        label: `Strike: ${strike.strike}`,
      }));
  
      // Update the chart data
      setChartData([...upperStrikes.reverse(), atmStrike, ...lowerStrikes]);
    }
  }, [data]);

  const getBarChartOptions = () => {
    const callTotal = chartData.reduce((sum, d) => sum + (d.call_buy_volume - d.call_sell_volume), 0);
    const putTotal = chartData.reduce((sum, d) => sum + (d.put_buy_volume - d.put_sell_volume), 0);
    const total = callTotal - putTotal;
  
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        formatter: (params) =>
          params
            .map(
              (p) =>
                `${p.seriesName}: ${formatNumber(p.value)}`
            )
            .join("<br>"),
      },
      legend: {
        top: "top",
        data: ["Call Buy", "Put Buy"],
      },
      graphic: [
        {
          type: "text",
          left: "75%", // Adjust position to the right of the legend
          top: 10,
          style: {
            text: `Call Total: {bold|${formatNumber(callTotal)}}, Put Total: {bold|${formatNumber(putTotal)}}, Total: {bold|${formatNumber(total)}}`,
            fontSize: 12,
            rich: {
              bold: {
                fontWeight: "bold", // Make text bold
              },
            },
            fill: "#000", // Text color
            lineHeight: 20,
          },
        },
      ],
      grid: { left: "10%", right: "10%", top: "15%", bottom: "20%" },
      xAxis: {
        type: "category",
        data: chartData.map((d) => d.label),
        axisTick: { show: false },
        axisLine: { show: true },
        axisLabel: { rotate: 45, interval: 0 },
      },
      yAxis: {
        type: "value",
        name: "Volume",
        axisLabel: {
          formatter: formatNumber,
        },
        axisLine: { show: true },
        splitLine: { lineStyle: { type: "dashed" } },
      },
      series: [
        {
          name: "Call Buy",
          type: "bar",
          data: chartData.map((d) => ({
            value: d.call_buy_volume - d.call_sell_volume,
            strike: d.strike, // Add strike info for click interaction
          })),
          barWidth: "20%",
          itemStyle: { color: "#5F8575" },
          label: {
            show: true,
            position: "insideTop",
            formatter: (params) => formatNumber(params.value),
            fontSize: 10,
            color: "#FFFFFF",
          },
        },
        {
          name: "Put Buy",
          type: "bar",
          data: chartData.map((d) => ({
            value: d.put_buy_volume - d.put_sell_volume,
            strike: d.strike, // Add strike info for click interaction
          })),
          barWidth: "20%",
          itemStyle: { color: "#DC143C" },
          label: {
            show: true,
            position: "insideTop",
            formatter: (params) => formatNumber(params.value),
            fontSize: 10,
            color: "#FFFFFF",
          },
        },
      ],
    };
  };

  const getLineChartOptions = () => {
    if (!hoverData) return {};

    return {
      title: {
        text: `Strike: ${selectedStrike}`,
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) =>
          params
            .map(
              (p) =>
                `${p.seriesName}: ${formatNumber(p.value)}`
            )
            .join("<br>"),
      },
      legend: {
        top: "top",
        data: ["Call Volume", "Put Volume"],
      },
      xAxis: {
        type: "category",
        data: hoverData.map((d) => d.time),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: formatNumber,
        },
      },
      series: [
        {
          name: "Call Volume",
          type: "line",
          data: hoverData.map((d) => d.call_volume),
          itemStyle: { color: "#5F8575" },
        },
        {
          name: "Put Volume",
          type: "line",
          data: hoverData.map((d) => d.put_volume),
          itemStyle: { color: "#DC143C" },
        },
      ],
    };
  };

  const handleBarClick = (params) => {
    if (params.componentType === "series" && params.data.strike) {
      const dataForModal = last10MinData[params.data.strike] || [];
      setHoverData(dataForModal); // Set data for line chart
      setSelectedStrike(params.data.strike); // Set strike for title
      setModalOpen(true); // Open modal
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
      {isHistorical && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Autocomplete
            options={timeFrames}
            value={selectedTime}
            onChange={(e, newValue) => {
              setSelectedTime(newValue);
              if (onTimeChange) onTimeChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Time" variant="outlined" size="small" />
            )}
            sx={{ flex: 1, maxWidth: "150px" }}
          />
        </Box>
      )}
      <ReactECharts
        option={getBarChartOptions()}
        style={{ height: "100%", width: "100%" }}
        onEvents={{
          click: handleBarClick,
        }}
      />
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "80%",
            backgroundColor: "white",
            padding: "16px",
            borderRadius: "8px",
            boxShadow: 24,
            position: "relative",
          }}
        >
          <Button
            onClick={() => setModalOpen(false)}
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              backgroundColor: "#f5f5f5",
              borderRadius: "50%",
              minWidth: "36px",
              minHeight: "36px",
              color: "#000",
              zIndex: 1300,
            }}
          >
            ✕
          </Button>
          {hoverData ? (
            <ReactECharts
              option={getLineChartOptions()}
              style={{ height: "100%", width: "100%" }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography>No data available</Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default BrokerBuyDiffChart;
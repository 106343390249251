import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import { useAuth } from "../../AuthContext";
import InfoIcon from "@mui/icons-material/Info";

const NiftyHistory = () => {
  const { auth, axiosInstance } = useAuth();
  const [expiries, setExpiries] = useState([]);
  const [tradeDates, setTradeDates] = useState([]);
  const [strikes, setStrikes] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [selectedTradeDate, setSelectedTradeDate] = useState(null);
  const [selectedStrike, setSelectedStrike] = useState(null);
  const [sellData, setSellData] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const [markerData, setMarkerData] = useState(null);

  useEffect(() => {
    const fetchExpiries = async () => {
      try {
        const response = await axiosInstance.get(`/get-expiries`);
        setExpiries(response.data.map((item) => item.expiry));
      } catch (error) {
        console.error("Error fetching expiries:", error);
      }
    };

    fetchExpiries();
  }, [auth.token]);

  const fetchTradeDates = async (expiry) => {
    try {
      const response = await axiosInstance.post(`/get-trade-dates`, { expiry });
      setTradeDates(response.data.map((item) => item.trade_date));
    } catch (error) {
      console.error("Error fetching trade dates:", error);
    }
  };

  const fetchStrikes = async (expiry, tradeDate) => {
    try {
      const response = await axiosInstance.post(`/get-strikes`, { expiry, trade_date: tradeDate });
      setStrikes(response.data);
    } catch (error) {
      console.error("Error fetching strikes:", error);
    }
  };

  const fetchMarkers = async (strike) => {
    try {
      const response = await axiosInstance.post(`/get-nifty-history-marker`, { expiry: selectedExpiry, trade_date: selectedTradeDate, strike: strike });
      return response.data;
    } catch (error) {
      console.error("Error fetching markers:", error);
      return { callMarkers: [], putMarkers: [] };
    }
  };

  // Handle click for title and info icon
  const handleHelpClick = () => {
    window.open("/help/history-strike", "_blank", "noopener,noreferrer");
  };

  const fetchData = async (strike) => {
    try {
      const [sellResponse, candleResponse, markerResponse] = await Promise.all([
        axiosInstance.post(`/get-history-sell-data`, { expiry: selectedExpiry, trade_date: selectedTradeDate, strike }),
        axiosInstance.post(`/get-history-candle-data`, { expiry: selectedExpiry, trade_date: selectedTradeDate, strike }),
        fetchMarkers(strike),
      ]);
      setSellData(sellResponse.data);
      setCandleData(candleResponse.data);
      setMarkerData(markerResponse);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  const handleExpiryChange = (expiry) => {
    setSelectedExpiry(expiry);
    setSelectedTradeDate(null);
    setSelectedStrike(null);
    setSellData(null);
    setCandleData(null);
    fetchTradeDates(expiry);
  };

  const handleTradeDateChange = (tradeDate) => {
    setSelectedTradeDate(tradeDate);
    setSelectedStrike(null);
    setSellData(null);
    setCandleData(null);
    fetchStrikes(selectedExpiry, tradeDate);
  };

  const handleStrikeChange = (strike) => {
    setSelectedStrike(strike);
    fetchData(strike);
  };

  return (
    <div style={{ width: "100%", minHeight: "calc(100vh - 120px)", fontFamily: "Roboto, sans-serif" }}>
      {/* Header Section */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
          padding: "20px",
        }}
      >
        <h1 style={{ marginBottom: "0" }}>Nifty History</h1>

        <Autocomplete
          options={expiries}
          value={selectedExpiry}
          onChange={(e, newValue) => handleExpiryChange(newValue)}
          renderInput={(params) => <TextField {...params} label="Select Expiry" variant="outlined" />}
          sx={{ flex: 1, minWidth: "200px" }}
        />

        {selectedExpiry && (
          <Autocomplete
            options={tradeDates}
            value={selectedTradeDate}
            onChange={(e, newValue) => handleTradeDateChange(newValue)}
            renderInput={(params) => <TextField {...params} label="Select Trade Date" variant="outlined" />}
            sx={{ flex: 1, minWidth: "200px" }}
          />
        )}

        {selectedTradeDate && (
          <Autocomplete
            options={strikes}
            value={selectedStrike}
            onChange={(e, newValue) => handleStrikeChange(newValue)}
            getOptionLabel={(option) => (typeof option === "object" ? option.strike.toString() : option.toString())}
            renderInput={(params) => <TextField {...params} label="Select Strike" variant="outlined" />}
            sx={{ flex: 1, minWidth: "200px" }}
          />
        )}
      </div>

      {/* Charts Section */}
      {sellData && 
            <div style={{ position: "relative", flex: "5", marginRight: "2%" }}>
              {/* Title Box */}
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  left: 8,
                  zIndex: 1400,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleHelpClick}
              >
                Strike wise Insights
                <InfoIcon
                  sx={{
                    fontSize: "1rem",
                    marginLeft: "4px",
                    cursor: "pointer",
                    color: "#0B99FF",
                  }}
                />
              </Box>
              {/* Line Chart */}
              {sellData && <LineChart data={sellData} />}
            </div>
          }

      {candleData && (
        <div
          className="chart-container"
          style={{
            margin: "20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <CandleChart data={candleData.CE} title="Call Candles" markers={markerData.callMarkers} />
          </div>
          <div style={{ flex: 1 }}>
            <CandleChart data={candleData.PE} title="Put Candles" markers={markerData.putMarkers} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NiftyHistory;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archiving {
  background-color: #ffcccb; /* Light red highlight */
  animation: fadeOut 0.5s ease-out forwards;
}

.subscription-item {
  opacity: 1;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.subscription-item.removing {
  opacity: 0;
  transform: translateX(-20px);
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-50px); /* Slight movement to the left */
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Users.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,wBAAwB;EACnD,yCAAyC;AAC3C;;AAEA;EACE,UAAU;EACV,0DAA0D;AAC5D;;AAEA;EACE,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,4BAA4B,EAAE,gCAAgC;EAChE;AACF","sourcesContent":[".archiving {\n  background-color: #ffcccb; /* Light red highlight */\n  animation: fadeOut 0.5s ease-out forwards;\n}\n\n.subscription-item {\n  opacity: 1;\n  transition: opacity 0.5s ease-out, transform 0.5s ease-out;\n}\n\n.subscription-item.removing {\n  opacity: 0;\n  transform: translateX(-20px);\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  to {\n    opacity: 0;\n    transform: translateX(-50px); /* Slight movement to the left */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

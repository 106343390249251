import React from "react";
import ReactEcharts from "echarts-for-react";

const EquityLineChart = ({ data }) => {
  // Format the data for the chart
  const formattedData = data.map((item) => ({
    candleTime: item.candleTime, // X-axis
    buyQuantity: item.buyQuantity, // Y-axis Buy
    sellQuantity: item.sellQuantity, // Y-axis Sell
  }));

  // Extract x-axis (time) and y-axis (quantities)
  const xAxisData = formattedData.map((item) => item.candleTime);
  const buyQuantities = formattedData.map((item) => item.buyQuantity);
  const sellQuantities = formattedData.map((item) => item.sellQuantity);

  // ECharts option
  const options = {
    title: {
      text: "Equity Line Chart",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    legend: {
      top: "5%",
      data: ["Buy Volume", "Sell Volume"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xAxisData,
      axisLabel: {
        formatter: (value) => {
          const date = new Date(value);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          return `${hours}:${minutes}`;
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1_000_000) {
            return `${(value / 1_000_000).toFixed(1)}M`;
          }
          if (value >= 1_000) {
            return `${(value / 1_000).toFixed(0)}K`;
          }
          return value.toString();
        },
      },
    },
    series: [
      {
        name: "Buy Volume",
        type: "line",
        data: buyQuantities,
        lineStyle: { color: "green" },
        itemStyle: { color: "green" },
      },
      {
        name: "Sell Volume",
        type: "line",
        data: sellQuantities,
        lineStyle: { color: "red" },
        itemStyle: { color: "red" },
      },
    ],
  };

  return <ReactEcharts option={options} style={{ width: "100%", height: "400px" }} />;
};

export default EquityLineChart;
import React, { useRef } from 'react';
import ReactECharts from 'echarts-for-react';

const CandleChart = ({ data, title, indications = [] }) => {
  const chartRef = useRef(null);
  indications = Array.isArray(indications) ? indications : [];
  console.log(indications);

  const formattedData = data.map((item) => ({
    ...item,
    formattedTime: (() => {
      const date = new Date(item.candleTime);
      return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    })(),
  }));

  const getChartOptions = () => {
    const prices = data.flatMap((item) => [item.open, item.close, item.low, item.high]);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const buffer = Math.ceil((maxPrice - minPrice) * 0.1);

    const markerPoints = indications.flatMap((indication) => {
      const match = formattedData.find((item) => {
        const indicationTime = new Date(indication.candleTime);
        const formattedTime = `${indicationTime.getHours().toString().padStart(2, '0')}:${indicationTime.getMinutes().toString().padStart(2, '0')}`;
        return item.formattedTime === formattedTime;
      });
      if (match) {
        const { formattedTime } = match;
        const markers = [];
        if (indication.slowSellers === 1) {
          markers.push({
            name: 'Sell',
            symbolSize: 30,
            coord: [formattedTime, match.high],
            itemStyle: { color: '#0B99FF' },
            label: { show: true, formatter: '', position: 'top' },
          });
        }
        if (indication.slowBuyers === 1) {
          markers.push({
            name: 'Buy',
            coord: [formattedTime, match.low],
            symbolSize: 30,
            symbolRotate: 180,
            itemStyle: { color: '#0B99FF' },
            label: { show: true, formatter: '', position: 'bottom' },
          });
        }
        return markers;
      }
      return [];
    });

    return {
      title: {
        text: title,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
      },
      grid: {
        top: '10%',
        bottom: '14%',
        left: window.innerWidth <= 768 ? '12%' : '6%',
        right: '5%',
      },
      xAxis: {
        type: 'category',
        data: formattedData.map((item) => item.formattedTime),
        axisLabel: { rotate: 45 },
      },
      yAxis: {
        type: 'value',
        name: 'Price',
        min: Math.floor(minPrice - buffer),
        max: Math.ceil(maxPrice + buffer),
        axisLabel: { formatter: (value) => Math.round(value) },
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          labelFormatter: (index) => formattedData[index]?.formattedTime || '',
        },
        { type: 'inside' },
      ],
      series: [
        {
          type: 'candlestick',
          name: title,
          data: data.map((item) => [item.open, item.close, item.low, item.high]),
          itemStyle: {
            color: 'green',
            color0: 'red',
            borderColor: 'green',
            borderColor0: 'red',
          },
          markPoint: {
            data: markerPoints,
          },
        },
      ],
    };
  };

  const handleDataZoom = (params) => {
    const instance = chartRef.current.getEchartsInstance();
    const startIndex = Math.round((params.start / 100) * data.length);
    const endIndex = Math.round((params.end / 100) * data.length);
    const zoomedPrices = data
      .slice(startIndex, endIndex)
      .flatMap((item) => [item.open, item.close, item.low, item.high]);
    const minPrice = Math.min(...zoomedPrices);
    const maxPrice = Math.max(...zoomedPrices);
    const buffer = Math.ceil((maxPrice - minPrice) * 0.1);
    instance.setOption({
      yAxis: {
        min: Math.floor(minPrice - buffer),
        max: Math.ceil(maxPrice + buffer),
        axisLabel: { formatter: (value) => Math.round(value) },
      },
    });
  };

  return (
    <ReactECharts
      ref={chartRef}
      option={getChartOptions()}
      style={{ height: '600px', width: 'calc(100% - 5px)' }}
      onEvents={{ dataZoom: handleDataZoom }}
    />
  );
};

export default CandleChart;
import React, { useState, useEffect, useRef } from "react";
import { Box, TextField, Typography } from "@mui/material";

const MathCaptcha = ({ onValidate }) => {
  const [question, setQuestion] = useState({ num1: 0, num2: 0, answer: 0 });
  const [userAnswer, setUserAnswer] = useState("");
  const [error, setError] = useState("");
  const canvasRef = useRef(null);

  // Generate a new question
  const generateQuestion = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setQuestion({ num1, num2, answer: num1 + num2 });
  };

  useEffect(() => {
    generateQuestion();
  }, []);

  // Draw the question on the canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
    ctx.font = "20px Arial";
    ctx.fillStyle = "#000";
    ctx.fillText(`${question.num1} + ${question.num2} = ?`, 10, 25);
  }, [question]);

  const handleValidation = () => {
    if (parseInt(userAnswer) === question.answer) {
      setError("");
      onValidate(true); // Notify parent about success
    } else {
      setError("Incorrect answer. Please try again.");
      onValidate(false); // Notify parent about failure
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      {/* Canvas Element */}
      <canvas ref={canvasRef} width={150} height={40} style={{ border: "1px solid #ccc" }} />

      {/* User Input */}
      <TextField
        label="Your Answer"
        variant="outlined"
        value={userAnswer}
        onChange={(e) => setUserAnswer(e.target.value)}
        onBlur={handleValidation} // Validate when user leaves the input
        required
        type="number"
        error={!!error}
        helperText={error}
        sx={{ width: "150px" }}
      />
    </Box>
  );
};

export default MathCaptcha;
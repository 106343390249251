import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../AuthContext";
import { baseAPIURL } from "../../Config";
import "../../styles/Login.css";

const Login = () => {
  const { login } = useAuth();
  const [credentials, setCredentials] = useState({
    phone: "",
    password: "",
    acceptedTerms: false,
  });

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  const validate = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/; // Exactly 10 digits

    // Phone validation
    if (!credentials.phone.trim() || !phoneRegex.test(credentials.phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits.";
    }

    // Password validation
    if (!credentials.password.trim() || credentials.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters.";
    }

    // Terms acceptance validation
    if (!credentials.acceptedTerms) {
      newErrors.acceptedTerms = "You must accept the Terms of Use and Privacy Policy.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError(""); // Clear previous API error message

    if (!validate()) {
      return;
    }

    try {
      const response = await fetch(`${baseAPIURL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      });

      const result = await response.json();

      if (result.status === "emailNotVerified") {
        setApiError("Your account is not verified yet. Please click on Forgot Password link below to verify your account and reset password.");
      } else if (result.status === "success") {
        const { sessionToken } = result;
        login(sessionToken, result.role);
        window.location.href = "/live-nifty-index";
      } else {
        setApiError(result.reason || "Unable to complete login at the moment!!");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setApiError("Unable to complete login at the moment!!");
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setCredentials({
      ...credentials,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <Box>
      {/* Body */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "80vh", px: 3 }}
      >
        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/login.svg"
            alt="Infographic"
            style={{ width: "100%", borderRadius: "10px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#1976d2",
              fontWeight: "bold",
              fontFamily: "'Roboto', sans-serif",
              textAlign: "center",
            }}
          >
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: "100%",
              maxWidth: 400,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              label="Phone Number"
              name="phone"
              variant="outlined"
              value={credentials.phone}
              onChange={handleChange}
              required
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              value={credentials.password}
              onChange={handleChange}
              required
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptedTerms"
                  checked={credentials.acceptedTerms}
                  onChange={handleChange}
                />
              }
              label={
                <Typography variant="body2">
                  I have read and agree to the{" "}
                  <Link href="/privacy-policy" target="_blank" rel="noopener">
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                    Terms of Use
                  </Link>.
                </Typography>
              }
            />
            {errors.acceptedTerms && (
              <Typography
                variant="body2"
                sx={{ color: "red", textAlign: "center" }}
              >
                {errors.acceptedTerms}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              sx={{ bgcolor: "#1976d2", color: "#fff" }}
            >
              Login
            </Button>
            {apiError && (
              <Typography
                variant="body2"
                sx={{ color: "red", mt: 1, textAlign: "center" }}
              >
                {apiError}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                mt: 2,
              }}
            >
              <Link href="/password-reset" underline="hover">
                Forgot Password?
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { Autocomplete, TextField, Box } from "@mui/material";

const StrikeComparisonChart = ({ data, onTimeChange, isHistorical, selectedTime = "15:29", setSelectedTime = () => {} }) => {
  const [chartData, setChartData] = useState([]);

  // Generate time frames from 9:15 to 15:29 at 1-minute intervals
  const generateTimeFrames = () => {
    const times = [];
    let hour = 9;
    let minute = 15;

    while (hour < 15 || (hour === 15 && minute < 30)) {
      times.push(`${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`);
      minute += 1;
      if (minute === 60) {
        hour += 1;
        minute = 0;
      }
    }
    return times;
  };

  const timeFrames = isHistorical ? generateTimeFrames() : [];

  useEffect(() => {
    if (data) {
      const atmStrike = {
        ...data.atmStrike,
        label: `ATM Strike: ${data.atmStrike.strike}`,
      };

      const lowerStrikes = data.lowerStrikes.map((strike) => ({
        ...strike,
        label: `Strike: ${strike.strike}`,
      }));

      const upperStrikes = data.upperStrikes.map((strike) => ({
        ...strike,
        label: `Strike: ${strike.strike}`,
      }));

      setChartData([...upperStrikes.reverse(), atmStrike, ...lowerStrikes]);
    }
  }, [data]);

  const getChartOptions = () => ({
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      formatter: (params) =>
        params
          .map(
            (p) =>
              `${p.seriesName}: ${p.value} (Strike: ${p.data.strike})`
          )
          .join("<br>"),
    },
    grid: { left: "10%", right: "10%", top: "20%", bottom: "20%" },
    xAxis: {
      type: "category",
      data: chartData.map((d) => d.label),
      axisTick: { show: false },
      axisLine: { show: true },
      axisLabel: { rotate: 45, interval: 0 }, // Rotate for better readability
    },
    yAxis: {
      type: "value",
      name: "Volume",
      nameLocation: "end",
      nameGap: 15,
      axisLabel: { formatter: "{value}" },
      axisLine: { show: true },
      splitLine: { lineStyle: { type: "dashed" } },
    },
    series: [
      {
        name: "Call Buy",
        type: "bar",
        data: chartData.map((d) => d.call_buy_volume),
        barWidth: "20%",
        itemStyle: { color: "#5F8575" },
        label: {
          show: true,
          position: "top",
          formatter: (value) => `${(value.value / 1_000_000).toFixed(1)}M`,
          color: "#000",
        },
      },
      {
        name: "Call Sell",
        type: "bar",
        data: chartData.map((d) => d.call_sell_volume),
        barWidth: "20%",
        itemStyle: { color: "#ff0000" },
        label: {
          show: true,
          position: "top",
          formatter: (value) => `${(value.value / 1_000_000).toFixed(1)}M`,
          color: "#fff",
        },
      },
      {
        name: "Put Buy",
        type: "bar",
        data: chartData.map((d) => d.put_buy_volume),
        barWidth: "20%",
        itemStyle: { color: "#DC143C" },
        label: {
          show: true,
          position: "top",
          formatter: (value) => `${(value.value / 1_000_000).toFixed(1)}M`,
          color: "#000",
        },
      },
      {
        name: "Put Sell",
        type: "bar",
        data: chartData.map((d) => d.put_sell_volume),
        barWidth: "20%",
        itemStyle: { color: "#008000" },
        label: {
          show: true,
          position: "top",
          formatter: (value) => `${(value.value / 1_000_000).toFixed(1)}M`,
          color: "#fff",
        },
      },
    ],
    legend: {
      data: ["Call Buy", "Call Sell", "Put Buy", "Put Sell"],
      top: "top",
      left: "center",
      orient: "horizontal",
      selected: {
        "Call Buy": true,
        "Call Sell": false, // Hidden by default
        "Put Buy": true,
        "Put Sell": false, // Hidden by default
      },
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      {isHistorical && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Autocomplete
            options={timeFrames}
            value={selectedTime}
            onChange={(e, newValue) => {
              setSelectedTime(newValue);
              if (onTimeChange) onTimeChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Time" variant="outlined" size="small" />
            )}
            sx={{ flex: 1, maxWidth: "150px" }}
          />
        </Box>
      )}
      <ReactECharts
        option={getChartOptions()}
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
        }}
      />
    </Box>
  );
};

export default StrikeComparisonChart;